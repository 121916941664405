
.language {
    order: 4;

    position: relative;

    &__toggle, &__toggle .flag-image {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin: 0;

        &:before {
            // added styling to critical/_header.scss
        }
    }

    &.active .language__dropdown {
        display: block;
    }

    &__dropdown {
        display: none;
        flex-direction: column;
        position: absolute;
        top: calc(100% + 16px);
        right: 0;
        z-index: 20;
        background: $color__white;
        box-shadow: 0 0 16px rgba(4, 7, 2, 0.16);
        border-radius: 4px;
        padding: 16px;

        &:after {
            content: "";
            position: absolute;
            top: -8px;
            right: 12px;
            background: $color__white;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
            border-radius: 2px;
            border: none;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            margin: 0;
            padding: 0;
        }

        a {
            padding: 4px 0;
            text-transform: uppercase;
            white-space: nowrap;
            display: block;
        }
    }

    .flag-image {
        max-width:unset;
        display: inline;
    }
}
