.hero {
    margin-bottom: 128px;

    > *:not(:first-child) {
        display: none;
    }

    .slick-slide {
    }

    .pagebuilder-slide-wrapper {
    }

    .pagebuilder-poster-content,
    .pagebuilder-collage-content {
        min-height: 420px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-top: 64px;
        padding-bottom: 120px;
        text-align: center;

        @include media('>=tablet_portrait') {
            padding-top: 32px;
            padding-bottom: 32px;
            text-align: left;
        }
    }

    .pagebuilder-collage-content {
        @include media('>=tablet_portrait') {
            align-items: flex-start;
        }
    }

    .pagebuilder-poster-content {
        @include container-wide;
        align-items: center;
    }

    .pagebuilder-overlay {
        @include container-wide;
        min-height: 540px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media('>=tablet_portrait') {
            justify-content: flex-start;
        }
    }

    .pagebuilder-collage-content {
        max-width: 544px;
    }

    //[data-appearance="collage-right"] .pagebuilder-overlay {
    //    margin-right: 32px;
    //}
    //
    //[data-appearance="collage-left"] .pagebuilder-overlay {
    //    margin-left: 32px;
    //}
    //
    //[data-appearance="collage-centered"] .pagebuilder-overlay {
    //    @include container-wide;
    //}

    .pagebuilder-slide-button {
        @include button();
        margin-top: 32px;
    }

    // Outer wrapper of the slides: Needs to be 100% wide to ensure coverage
    [data-content-type="slide"] > a {
        display: block;
        width: 100%;
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        @include heading-h1;
        text-align: center;

        @include media('>=tablet_portrait') {
            text-align: left;
        }
    }

    p, a {
        font-size: 20px;
    }

    .slick-dots {
        @include container-wide;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 64px;
        left: 50%;
        width: 100%;
        margin: 0 auto;
        transform: translateX(-50%);

        @include media('>=tablet_portrait') {
            bottom: 72px;
            right: auto;
            justify-content: flex-start;
        }


        li {
            display: block;
            width: 8px;
            height: 8px;
            background: $color__brand;
            opacity: .64;
            border-radius: 100%;
            margin: 8px;
            cursor: pointer;

            &.slick-active {
                opacity: 1;
                width: 12px;
                height: 12px;
            }
        }

        button {
            display: none !important;
        }
    }
}
