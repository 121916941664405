.breadcrumbs {
    @include container-wide;
    margin-top: 16px;
    margin-bottom: 16px;

    @include media('>=tablet_portrait') {
        margin: 24px auto;
    }

    .items {
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 1;
        display: flex;
        align-items: center;

        .item {
            display: flex;
            align-items: center;

            &, a {
                @include text;
                color: $color__grey--2;
                text-decoration: none;
                margin-bottom: 0;
            }

            // '>'
            &:not(:last-child):after {
                content: ">";
                color: $color__grey--3;
                margin: 0 8px;
            }

            strong {
                color: $color__grey--3;
                font-weight: normal;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

}
