.toolbar.toolbar-products {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .modes {
        display: none !important;

        &-label {
            display: none;
        }
    }

    .toolbar-amount {
        margin-bottom: 0;
        flex-grow: 1;
    }

    .toolbar-sorter {
        display: flex;
        align-items: center;
        gap: 4px;

        .sorter-label {
            flex: 0 0 auto;
        }

        .sorter-options {
            margin-bottom: 0;

            line-height: 48px;
        }

        .sorter-action {
            display: flex;
            height: 24px;
            width: 24px;
            flex: 0 0 24px;
            background: $color__brand;
            mask-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 12h3v6h3v-6h3l-4.5-7.5L7 12Z" fill="currentColor"/></svg>');
            mask-position: center center;

            &.sort-desc {
                transform: rotate(180deg);
            }


            span {
                display: none;
            }
        }
    }

    .pages {
        order: 10;
    }
}
