.product-page--options {
    @include container-wide();
    margin-bottom: 128px;
}

.product-add-form {
    background: $color__canvas;
    border-radius: 16px;
    box-shadow: 0 4px 18.7px 0 rgba(0,94,43,.25);
    padding: 32px;

    @include media('>=desktop') {
        padding: 40px 96px;
    }

    &__title {
        @include heading-h4;
        color: #4F4F4F !important;
        margin-bottom: 24px;
        font-weight: bold;
    }

    .product-options-wrapper > .fieldset {
        display: grid;
        grid-gap: 32px 128px;

        @include media('>=desktop') {
            grid-template-columns: repeat(2, 1fr);
        }

        .field .control {
            width: auto;
        }

        > .field > label {
            @include heading-h5;
            margin-bottom: 24px;
        }
    }
}
