
.page-main {
    .button,
    [type="submit"],
    [type="reset"],
    [type="button"]:not(.trigger),
    .action.primary {
        @include button;

        &[disabled] {
            color: $color__black !important;
            background-color: rgba($color__black, .5) !important;
            opacity: .5;
            cursor: not-allowed;
        }

        &.button--icon {
            padding-left: 56px;

            &:before {
                content: "play_circle_outline";
                position: absolute;
                top: 12px;
                left: 16px;
            }
        }

        &.button--contrast {
            @include button--contrast;
        }
    }

    .actions-toolbar {
        display: flex;
        align-items: center;
        gap: 16px;

        &:before,
        &:after {
            content: unset;
        }

        .primary {
            a.action {
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            .action:not(:last-child) {
                margin-right: 40px;
            }
        }

        .secondary {
            font-size: 16px;

            a.action {
                margin-top: 0;
            }
        }
    }
}
