
#main-content {
  display: block;
  margin: 0;
}

.layout-builder,
.layout-builder-block {
  background-color: transparent;
}

//.layout-builder__add-section {
//  display: none;
//}

.ui-dialog .ui-dialog-content {
  max-height: 90vh !important;
}

summary {
  user-select: none;
  outline: none;

  &:focus {
    outline: none;
  }

  span {
    pointer-events: none;
  }
}

.play-video {
  cursor: pointer;
}

figure {
    margin: 0;
}

table {
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
    border: none;
}

table caption {
    display: none;
}

pre {
  // Sorry to any developer who needs this
  white-space: pre-wrap;
  background: rgba(#629965, .1);
  border: 1px solid rgba(#629965, .3);
  color: #111;
  line-height: 1.42857143;
  margin: 0 0 10px;
  padding: 10px;
  font-size: 1.2rem;
  display: block;
  word-wrap: break-word;
}

// override of Ninja menu
.subtitle {
    margin-top: 0;
}

.required-captcha.checkbox {
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
}

.bss_overlay {
    background-color: #FFFFFF;
    height: 100%;
    left: 0;
    opacity: 0.5;
    filter: alpha(opacity=50);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 55555555555555;
    display: none;
    visibility: initial;

    img {
        top: 40%;
        left: 45%;
        display: block;
        position: fixed;
    }
}

.bss_slider_container {
    width: calc(100% - 20px);
    margin: 0 15px 0 5px;
}

.page-wrapper {
    overflow: hidden;
}

.points-loader {
    display: none;
    width: 16px;
}
