.category-view {
    position: relative;
    margin-bottom: 64px;

    .category-hero {
        @include container-wide;
        position: relative;
        padding-top: 56px;
        padding-bottom: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;

        @include media('>=tablet_portrait') {
            min-height: 480px;
            gap: 32px;
        }
    }

    .page-title-wrapper {
        position: relative;

        .page-title {
            @include heading-h3;
            color: $color__brand;
        }
    }

    .category-description, .category-manchet {
        @include text;
    }

    .pagebuilder-button-primary {
        @include button;
        margin-top: 32px;
    }

    .category-image {

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 16px;
            box-shadow: 0 4px 18.7px 0 rgba(0, 94, 43, 0.25);
        }
    }
}
