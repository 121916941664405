@import "../_atoms/button";

.sales-order-view {

    .order-actions-toolbar .actions {
        a {
            @include button();
        }

        a:last-child {
            display: none;
        }
    }
}
