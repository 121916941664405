@import "../_atoms/button";

$checkout__title-before-background: $color__brand !default;

@mixin bss-title-before() {
    font-family: $text-style__text-font;
    background: $checkout__title-before-background !important;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    color: $color__white;
    font-size: 20px;
    font-weight: 400;
    box-shadow: none;
    position: static;
    border: none;
    margin: -4px 8px 0 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.checkout-index-index {
    counter-reset: step-title;

    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .page-title-wrapper {
        display: none;
    }

    .columns {
        @include container-wide;
        margin-bottom: 64px;

        &:after {
            content: "";
            clear: both;
            display: block;
        }
    }

    .checkout-container {
        margin-top: 32px;
    }

    .step-title {
        @include heading-h4;

        &:before {
            content: counter(step-title);
            counter-increment: step-title;
            @include bss-title-before;
        }
    }

    .substep-title {
        @include heading-h5;
    }

    ol#checkoutSteps {
        @include grid-standard(1);
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 0;
        }

        @include media('>=tablet_portrait') {
            @include grid-standard(2);
            grid-template-rows: min-content 1fr;
        }
    }

    .step-content > * {
        margin-bottom: 32px;
    }

    fieldset {
        background: none;
        padding: 0;
        margin: 0;

        legend {
            display: none;
        }
    }

    .control {
        input,
        select {
            min-width: auto;
            padding: 0 16px;
        }
    }

    .field.choice {
        width: 100%;
    }

    .newaddress-save-button {
        color: $color__white;
        padding: 0 16px;
        height: 48px;
        border: 1px solid transparent;
        background-color: $color__brand;
    }

    .newaddress-button-title .action-toggle,
    .discount-code .payment-option-title .action-toggle,
    .iosc-comment .iosc-comment-title .action-toggle {
        color: $color__brand;
    }

    .discount-code,
    .iosc-comment,
    .iosc-subscribe {
        padding: 0;
    }

    .payment-option.rewards-block {
        .action-toggle {
            color: $color__brand;

            &:after {
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 16px;
                color: #8f8f8f;
                content: '\f004';
                font-family: 'osc_icons';
                margin: 0 0 0 10px;
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
        }

        &._active .action-toggle:after {
            content: '\f005';
        }

        .checkbox-group {
            display: flex;
            align-items: center;
            margin: 8px 0;
        }

        b {
            font-weight: bold;
            margin-left: 4px;
        }
    }

    .payment-option.discount-code {
        .payment-option-title {
            display: none;
        }

        .payment-option-inner {
            flex: 1 0 auto;
        }
    }

    #discount-form {
        display: flex;
        align-items: center;
        gap: 8px;

        .field {
            margin: 0;
        }

        .actions-toolbar {
            margin: 0;
            flex: 1;
        }

        .action-apply {
            padding: 8px;
            min-width: auto;
            margin: 0;
        }
    }

    #iosc-comment {
        label:empty {
            display: none;
        }
    }

    #giftwrap-checkbox {
        border-radius: 16px;
        background: $color__white;
        box-shadow: 0 4px 4.9px 0 rgba(1, 83, 39, 0.12);
        overflow: hidden;
        height: 100%;
        margin-bottom: 32px;
        padding: 24px;
        flex-direction: column;

        > div {
            display: flex;
            padding: 4px 0;
        }

        input[type="checkbox"] {
            --input-size: 24px;
            margin-right: 16px !important;
        }

        label {
            margin: 0 !important;
        }
    }

    .opc-wrapper {
        ol li {
            float: none;
            width: auto;
        }

        .form-login,
        .form-shipping-address,
        .methods-shipping {
            background-color: transparent !important;
        }

        .form-login .note {
            display: none !important;
        }

        #shipping {
            #checkout-step-shipping {
                .not-selected-item {
                    cursor: pointer;
                }

                #iosc_billingaddress {
                    padding: 8px 16px;
                    background-color: $color__brand !important;
                }
            }

            //.actions-toolbar .action {
            //    margin-bottom: 16px;
            //}
        }

        #opc-shipping_method {
            #checkout-step-shipping_method {
                margin-bottom: 0;

                #shipping-method-buttons-container {
                    display: none;
                }
            }
        }

        #payment {
            hr {
                display: none;
            }
        }

        .payment-virtual {
            margin-top: 0 !important;
            width: 100% !important;
        }
    }

    #co-shipping-form {
        overflow: visible;
    }

    #iosc-billing-container {
        order: 2;
        //grid-column: 1;
    }

    #opc-sidebar {
        float: right;
        width: 100%;
        margin-top: 30px;

        .items-in-cart {
            .title {
                @include text;
                font-weight: bold;
            }
        }
    }

    .error-message {
        color: #e02b27;
        display: block;
    }

    #paypay-in-context-button {
        &.disable {
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        }
    }

    #paypay-in-context-validate-error {
        color: #e02b27;
    }

    .order-comment-block {
        margin-bottom: 16px;
    }

    .checkout-agreements {
        margin-bottom: 32px;

        .checkout-agreement {
            margin-top: 8px;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        input {
            flex: 0 0 var(--input-size);
            margin-top: 4px;
        }

        label {
            margin: 0;
        }

        .mage-error {
            top: 0;
        }

        button {
            all: revert;
            @include text-link;
            white-space: break-spaces !important;
            height: auto !important;
            background: none !important;
            font-size: 16px !important;
            font-weight: normal !important;
            box-shadow: none !important;
            text-align: left !important;
            padding: 0 !important;
            margin: 0 !important;
            text-transform: none !important;
            color: $color__brand !important;
            text-decoration: underline !important;
        }
    }

    .actions-toolbar {
        margin: 8px 0 24px;

        .mage-error {
            top: 0;
        }

        .primary {
            width: 100%;
        }
    }

    div.iosc-place-order-container button.iosc-place-order-button,
    button.checkout {
        all: revert;
        @include button();
        @include button--contrast();
        width: 100%;
        background-color: $color__brand !important;
        font-size: 16px !important;
        border: none !important;
        margin-right: 0;
    }

    .osc_widget.under_place_order_button {
        margin: 24px 0 0;
        position: relative;
        border-radius: 16px;
        background: $color__white;
        box-shadow: 0 4px 4.9px 0 rgba(1, 83, 39, 0.12);
        overflow: hidden;
        padding: 24px;

        p {
            font-size: 14px;
            line-height: 16px;
        }
    }

    .iosc-registration {
        box-shadow: none;
        border: none;
        background: $color__brand;
        border-radius: 0;
        width: 100%;

        .password-toggle {
            display: flex;
            align-items: center;
        }
    }

    .minicart-items .product-item-details {
        display: block;
        width: 100%;
    }


    /**
     * Override default form element styling
     */

    input[type="text"], input[type="password"],
    input[type="tel"], input[type="email"],
    input[type="date"], input[type="datetime-local"],
    input[type="month"], input[type="week"],
    input[type="number"], input[type="url"],
    input[type="time"], input[type="search"]:not(.select2-search__field),
    textarea, select, .select2-container {
        background-color: transparent;
        border: 1px solid rgba($color__brand, .72);
    }

    label {
        color: $color__black;
        margin: 0 !important;
    }

    .field {
        margin: 8px 0 0;

        &.street .field {
            margin-bottom: 16px;
        }
    }

}

//
//  Mobile
//  _____________________________________________

@include media('<tablet') {
    .checkout-index-index {
        .opc-wrapper {
            #shipping {
                width: 100%;
            }

            #opc-shipping_method {
                //overflow-x: auto;
                //overflow-y: hidden;
                width: 100%;
                margin-top: 25px;
            }
        }

        #iosc-summary {
            width: 100%;
            clear: both;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include media('>=tablet') {
    .checkout-index-index {

        .opc-wrapper {
            width: 66.66%;
            padding-right: 32px;
            float: left;
        }

        #opc-sidebar {
            width: 33.33%;
            margin-top: 0;
        }

        #shipping {
            grid-row: span 2;
        }
    }
}
