
.featured-products {
    @include container-wide;
    margin-bottom: 128px;

    [data-content-type="heading"] {
        @include heading-h5;
        color: $color__grey--1;
        margin-bottom: 0;
        text-align: center;
    }

    .products-grid {
        margin-top: 40px;

        .product-items {
            @include media('>=tablet') {
                @include grid-standard(4);
            }
        }
    }

    // hide comparison
    .actions-secondary {
        display: none !important;
    }

}
