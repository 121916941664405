.block-search {
    float: none;
    position: relative;
    z-index: 20;
    margin: 0;

    @include media('>tablet') {
        padding-left: 0;
        margin-left: 16px;
    }

    .block-title,
    .nested,
    .label span,
    .action.search span {
        display: none;
    }

    .field.search {
        margin: 0;

        label {
            width: 40px;
            height: 40px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            margin: 0;
        }

        input[type="text"] {
            padding-right: 40px;
            margin: 0;
            background: $color__canvas;
            border: 1px solid $color__brand;
            color: $color__brand;
            border-radius: 8px;

            @include media('>tablet') {
                min-width: 320px;
            }
        }
    }

    .field.search label,
    .label {
        @include media('>tablet') {
            display: none;
        }
    }

    .label:before {
        // added styling to critical/_header.scss
    }

    .control {
        border: none;
        margin: 0;

        @include media('<=tablet') {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            transition: opacity 300ms;
            background: $color__canvas;
            padding: 32px;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
        }
    }

    .minisearch.active .control {
        @include media('<=tablet') {
            max-height: 100px;
            padding-bottom: 16px;
            opacity: 1;
        }
    }

    input {
        position: static;
    }

    .action.search {
        display: none;

        @include media('>tablet') {
            display: flex;
            background: none;
            border: 0;
            position: absolute;
            right: 0;
            top: 0;
            padding: 12px;
            bottom: 0;
            align-items: center;
        }

        &:before {
            // added styling to critical/_header.scss
        }
    }

    .search-autocomplete {
        display: none;
        margin-top: -15px;
        overflow: hidden;
        position: absolute;
        z-index: 3;

        @include media('>tablet') {
            margin-top: 0;
        }
    }
}

