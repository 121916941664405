
.product.data.items {
  margin-left: 0;
  margin-right: 0;

  //.review-control-vote label:before,
  //.review-control-vote:before {
  //  letter-spacing: 0;
  //}

  > .item.content {
    border: none;
    margin-top: 16px;
  }

  .additional-attributes-wrapper {
    th {
      width: 20%;
    }
  }
}