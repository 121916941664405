.bss-delivery {
  margin-bottom: 32px !important;

  #shipping_arrival_date,
  #delivery_arrival_date {
    max-width: none;
    margin: 10px 0;
  }

  .shipping_arrival_date .control {
    position: relative;

    .ui-datepicker-trigger {
      position: absolute;
      inset: 0;
      display: flex;
      width: 100%;
      height: 48px;
      justify-content: flex-end;
      background: transparent;

      &:before {
        content: "";
        background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 448 512"><path fill="%23555555" d="M128 0c17.7 0 32 14.3 32 32v32h128V32c0-17.7 14.3-32 32-32s32 14.3 32 32v32h48c26.5 0 48 21.5 48 48v48H0v-48c0-26.5 21.5-48 48-48h48V32c0-17.7 14.3-32 32-32zM0 192h448v272c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16z"/></svg>');
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        width: 24px;
        height: 24px;
      }

      span {
        display: none;
      }
    }

  }
}

//._has-datepicker {
//  ~ .ui-datepicker-trigger {
//    .lib-button-reset();
//    .lib-icon-font(
//  @_icon-font-content: @icon-calendar,
//  @_icon-font-color: @primary__color__lighter,
//  @_icon-font-size: @icon-calendar__font-size,
//  @_icon-font-line-height: @icon-calendar__font-size,
//  @_icon-font-display: block,
//  @_icon-font-text-hide: true
//  );
//    display: inline-block;
//    margin-top: -4px;
//    vertical-align: middle;
//
//    &:focus {
//      box-shadow: none;
//      outline: 0;
//    }
//  }
//}