
$newsletter__button-background: $color__white !default;

.footer-newsletter {
    display: flex;
    flex-direction: column;

    .block {
        display: block !important;
    }

    .block.widget {
        // Headline + teaser text inside pagebuilder block inside widget

    }

    .block.newsletter {
        // Subscribe block
        margin-bottom: 0;
        max-width: 360px;

        .title,
        .label {
            display: none;
        }

        .form.subscribe {
            display: flex;
        }

        .control {
            position: relative;

            &:before {
                content: "";
                width: 24px;
                height: 16px;
                background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translateY(-50%);
            }
        }

        label {
            margin: 0;
        }

        input[type="email"] {
            background: $color__white;
            color: $color__black;
            width: 224px;
            padding-left: 32px;
            //font-size: 14px;
            min-height: 40px;
            border-radius: 0;
            border: 0;
            margin-bottom: 0;

            &.mage-error {
                color: $color__form--error;
            }
        }


        div.mage-error {
            position: absolute;
            color: #fff;
            top: 100%;
            font-size: 12px;
        }


        .action.primary {
            @include button();
            @include button--contrast;
            box-shadow: none;
            //color: $color__white;
            //text-transform: uppercase;
            border-radius: 0;
            border: 0;
            width: 96px;
            height: 40px;
            min-width: auto;
            //font-size: 12px;
        }
    }

}