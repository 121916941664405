.items.order-links.rewards-links {
    display: block;
    padding: 0;
    margin: 0 0 32px;
}

//@media only screen and (max-width: 767px) {
@include media('<tablet') {
    .items.order-links.rewards-links {
        //display: block;
        //margin-bottom: 5px;
    }
}
