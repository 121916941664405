.create-account {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: rgba($color__black, .04);
    margin: 16px 0;
    padding: 16px;

    .field {
        margin-bottom: 0;
        flex: 1 0 100%;
    }
}
