
/** COLORS **/
$color__brand: #629965;



$color__yellow: #DBB21F; // not in color scheme
$color__red: #A31717; // not in color scheme

$color__black: #1A1A1A;
$color__grey--1: #555555;
$color__grey--2: #4F4F4F;// not in color scheme
$color__grey--3: #828282;// not in color scheme
$color__grey--4: #B5B5B5;
$color__grey: #F1F1F1;
$color__canvas: #FFFCF9;
$color__white: #fff;

$color__form--success: darken($color__brand, 20%);
$color__form--error: $color__red;

