
$button__background: $color__brand !default;
$button__text-color: $color__white !default;

$button__background--contrast: $color__brand !default;


@mixin button($background: $button__background) {
  font-weight: 700;
  color: $button__text-color;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  min-width: 192px;
  height: 44px;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity 300ms;
  text-decoration: none;
  position: relative;
  border: 1px solid transparent;
  background-color: $background;
  box-shadow: none;
  border-radius: 8px;

  &:hover {
    opacity: .8;
  }
}

@mixin button--contrast() {
  background: linear-gradient(180deg, #F89700 0%, #F89700 0.01%, #FCB240 100%);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

@mixin button--white() {
    background-color: $color__white;
    color: $color__black;
}

@mixin button--small() {
  padding: 8px 24px;
  border-radius: 8px;
  min-width: auto;
}

