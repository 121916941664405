
$seo__background-color: $color__brand !default;

.seo,
//.category-seo-attribute
{
    @include container-wide;
    padding: 56px 0;
    position: relative;
    background-color: $seo__background-color;
    margin-bottom: 0;

    @include media('>=tablet') {
        padding: 88px 40px;
        background-image: url(../images/seo-left.png), url(../images/seo-right.png);
        background-position: -16% top, calc(100% + 16%) bottom !important;
        background-repeat: no-repeat;
        background-size: auto !important;
    }

    > div,
    &__content {
        @include container-narrow;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        //gap: 16px;
    }

    [data-content-type="text"]:not(:last-child) {
        margin-bottom: 48px;
    }

    h2 {
        @include heading-h3;
        color: $color__brand;
        margin: 32px 0 16px;
    }

    h3 {
        @include heading-h4;
        color: $color__brand;
    }
}
