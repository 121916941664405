
$category-products-heading__heading-color: $color__black !default;

.category-products-heading {
    @include container-wide;

    h3 {
        @include heading-h1;
        margin-bottom: 0;
        color: $category-products-heading__heading-color;
    }
}
