.catalog-category-view.page-layout-category-block-only {

    .category-cms {
        // Wrapper for all the content
    }

    .category-block_hero figure {
        align-self: center;
        margin-top: 24px;
    }

}
