.control._with-tooltip {
  position: relative;

  input {
    margin-right: 10px;
    width: calc(100% - 39px);
  }
}

.field-tooltip {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 8px;

  &._active {
    z-index: 100;

    .field-tooltip-content {
      display: block;
    }
  }

  .label {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .field-tooltip-action {
    display: inline-block;
    text-decoration: none;

    &:before {
      content: '';
      background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="%23555555" d="M464 256a208 208 0 1 0-416 0 208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0 256 256 0 1 1-512 0zm168-72c0-30.9 25.1-56 56-56h56.9c34.9 0 63.1 28.3 63.1 63.1 0 22.6-12.1 43.5-31.7 54.8L280 264.4V296h-48v-59.4l12.1-6.9 44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1 0-8.4-6.8-15.1-15.1-15.1H224c-4.4 0-8 3.6-8 8v6.5h-48V184zm64 152h48v48h-48v-48z"/></svg>');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      width: 24px;
      height: 32px;
      vertical-align: middle;
    }
  }

  .field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2;

    &:before,
    &:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      border-right-color: #f4f4f4;
      left: -21px;
      top: 12px;
      content: '';
      display: block;
      position: absolute;
      z-index: 3;
    }

    &:before {
      border-right-color: #666;
    }

    &:after {
      border-right-color: #f4f4f4;
      width: 1px;
      z-index: 4;
    }
  }
}