.minicart-items {
    list-style: none;
    margin: 0;
    padding: 0;

    .product-item {
        padding: 12px 0;
        margin: 0;

        &:not(:last-child) {
            border-bottom: 1px solid $color__grey;
        }
    }

    .product {
        display: flex;
        flex-wrap: wrap;
        gap: 0 16px;
        width: 100%;
    }

    .product-item-photo {
        margin: 0;
    }

    .product-image-container {
        &, img {
            display: block;
            width: 96px !important;
            height: 96px !important;
            object-fit: contain;
            flex: 0 0 96px;
            border-radius: 0;
        }
    }

    .product-item-name {
        grid-row: 1 / span 2;
        align-self: center;

        a {
            @include heading-product-title;
            font-family: $text-style__text-font;
            font-weight: 600;
            text-decoration: none;
            margin: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .product-item-details {
        display: grid;
        grid-template-columns: 1fr 112px;
        gap: 0 32px;
        flex: 1;

        .product-item-pricing {
            align-self: end;

            .price {
                @include heading-product-title;
                font-weight: 600;
            }
        }

        .product.options {
            display: none;
        }

        .subtotal {
            text-align: left;
            float: none;

            .price {
                @include heading-product-title;
                font-weight: 600;
            }

            [data-label]:after {
                content: "(" attr(data-label) ")";
                display: block;
                font-size: 0.8em;
                white-space: nowrap;
            }
        }

        .actions {
            grid-column: 2;
        }
    }

    .qty-button {
        display: none;
    }

    .details-qty {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        margin-right: 8px !important;

        &.visible {
            display: flex !important;
            margin: 0 !important;
            height: 48px;

            input {
                min-width: 40px;
                max-width: 40px;
                padding: 0;
                text-align: center;
                height: 32px;
                min-height: 32px;
            }
        }

        .label {
            margin: 0;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    .qtybuttons {
        display: flex;
        float: right;
        margin: 0;
        align-items: center;
        height: 48px;

        a {
            margin: 0;
        }

        a.delete {
            order: 3;
            margin-left: 8px;

            &:before {
                font-family: revert;
                content: url('data:image/svg+xml; utf8, <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 6h18M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m3 0v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6h14ZM14 11v6M10 11v6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            }
        }

        a.remove,
        a.add {
            &:before {
                color: $color__brand;
                font-size: 20px;
            }
        }
    }

    .price-container {
        [data-label]:after {
            content: "(" attr(data-label) ")";
            display: block;
            font-size: 0.8em;
            white-space: nowrap;
        }
    }

    input.qty {
        max-width: 100px;
        min-width: auto;
        margin: 0;
        text-align: center;
    }

    .actions {
        display: flex;
        flex-direction: row-reverse;
    }

    .action {
        @include text-link;
    }

}
