.pager {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    gap: 16px;

    @include media('<tablet_portrait') {
        flex-direction: column;
    }

    .toolbar-amount {
        margin-bottom: 0;
    }
}
