
.additional-attributes {
    background: transparent;
    //max-width: 554px; // 6 cols

    tr:nth-child(odd) {
        background: rgba(#fff, .56);
    }

    th, td {
        @include text;
        padding: 8px 32px;
        text-align: left;
    }

    th {
        font-weight: 600;
    }
}
