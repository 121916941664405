
.page-usp,
.frontpage-usp {
    @include container-wide;
    margin-bottom: 128px;

    p {
        margin-bottom: 0;
    }

    svg {
        margin-bottom: 16px;
    }

    [data-content-type="block"],
    [data-content-type="row"] {
        height: 100%;
    }

    .block {
        margin-bottom: 0;
        height: 100%;
    }

    .pagebuilder-column-line {
        gap: 32px;
    }

    .usp-block--delivery {
        padding: 33px 24px 33px 28px;
        border-radius: 16px;
        //background: linear-gradient(180deg, #F89700 0%, #F89700 0.01%, #FCB240 100%);
        background: $color__brand;
        box-shadow: 0 4px 10.3px 0 rgba(0, 101, 47, 0.22);
        min-height: 216px;
        height: 100%;
        justify-content: center !important;

        &, p, a {
            color: $color__white;
            text-align: center;
        }

        strong {
            font-size: 20px;
        }
    }

    .usp-block--contact-us {
        position: relative;
        padding: 33px 24px 33px 28px;
        border-radius: 16px;
        background: $color__white;
        box-shadow: 0 4px 4.9px 0 rgba(1, 83, 39, 0.12);
        overflow: hidden;
        min-height: 216px;
        height: 100%;

        @include media('<tablet') {
            text-align: center;
        }

        .pagebuilder-column {
            img {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 290px;
                height: 100% !important;
                object-fit: cover;
                max-width: 50% !important;

                @include media('<tablet') {
                    display: none;
                }
            }
        }

        .pagebuilder-button-primary {
            @include button();
            @include button--small;
            @include button--contrast;
            margin-top: 16px;
        }
    }
}

.page-usp {
    @include grid-standard();

    @include media('<laptop') {
        @include grid-container(1);
    }

    .block-static-block:first-child {
        grid-column: 2 / span 4;

        @include media('<laptop') {
            grid-column: 1;
        }
    }

    .block-static-block:last-child {
        grid-column: 6 / span 6;

        @include media('<laptop') {
            grid-column: 1;
        }
    }
}

