.block-wishlist {
    .block-title {
        font-weight: bold;
        text-transform: uppercase;
    }

    .subtitle {
        display: none !important;
    }

    .empty {
        color: rgba($color__black, .4);
    }
}
