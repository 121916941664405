$product-bundle-summary__background-color: $color__brand !default;

.product-bundle {
    @include grid-container(2);
}

.bundle-options-container {
    @include container-wide;

    .product-add-form form {
        @include grid-standard(1);
        align-items: start;

        @include media('>=tablet') {
            @include grid-standard(2);
        }
    }

    .legend.title {
        @include heading-h3;
        color: $color__brand;
    }

    .options-list {
        gap: 0;
    }

    .nested .field.qty {
        display: none;
    }

    .option-content {
        > .label {
            display: none;
        }

        .field .label {
            align-items: center;
        }

        .bundle-option__media {
            width: 128px;
            height: 128px;
            flex: 0 0 128px;
            margin-right: 24px;

            * {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .product-name {
            flex: 1 1;
        }

        .price-notice {
            white-space: nowrap;

            .price-tax-label {
                display: block;
                font-size: 0.8em;
            }
        }
    }


    .block-bundle-summary {
        padding: 40px;
        background: $product-bundle-summary__background-color;

        @include media('>=tablet') {
            margin-top: 56px;
        }

        .title {
            @include heading-h2;
            color: $color__brand;
        }

        .bundle-details-wrapper {
            display: flex;
            flex-direction: column;
            gap: 40px;
            border-bottom: 1px solid #C4C4C4;
            margin-bottom: 24px;
            padding-bottom: 24px;
            align-items: flex-start;

            @include media('>=tablet') {
                flex-direction: row;
            }

            .bundle-info {
                max-width: 160px;
                flex: 0 0 160px;

                * {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .subtitle {
                display: none;
            }
            .bundle.items {
                list-style: none;
                padding-left: 0;
            }
        }


        .price-box {
            display:flex;
            justify-content: space-between;

            &:before {
                content: 'Total';
                line-height: 1.33em;

                @include media('>tablet_portrait') {
                    line-height: 40px;
                }
            }

            .price {
                @include heading-h3;
                color: $color__brand;
            }
        }

        .box-tocart {
            margin-top: 16px;

            .actions {
                flex-direction: row-reverse;
            }
        }
    }
}
