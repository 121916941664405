.page.messages {
    @include container-wide();
    margin-bottom: 0;
}

.message {
    @include text;
    display: block;
    background: #e5efe5;
    background: rgba(#e5efe5, .5);
    padding: 16px 24px 16px 40px;
    position: relative;
    margin: 8px 0 16px !important;

    &.success {
        color: $color__form--success;
    }
    &.error {
        color: $color__form--error;
    }

    .message.success>*:first-child:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: 28px;
        color: #006400;
        content: '\f14a';
        font-family: "Font Awesome 5 Pro";
        margin: -14px 0 0;
        vertical-align: middle;
        display: inline-block;
        font-weight: 400;
        overflow: hidden;
        speak: none;
        left: 0;
        top: 18px;
        width: 40px;
        position: absolute;
        text-align: center;
    }
}
