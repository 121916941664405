.mst-rewards-account-amount-wrapper {
    display:       flex;
    align-items:   flex-end;
    margin-bottom: 25px;

    .mst-rewards-account-amount {
        display:     flex;
        align-items: flex-end;

        .points {
            font-size:   36px;
            font-weight: bold;
            line-height: 36px;
        }

        .unit {
            color:       #6d6d6d;
            margin-left: 5px;
        }

        > img {
            height: 28px;
        }
    }

    .mst-rewards-account-subtitle {
        font-size:     16px;
        margin-bottom: 10px;
        font-weight:   600;
    }

    .mst-rewards-logo {
        height:      36px;
        margin-left: 15px;
    }
}
