.product-availability {
    @include text-menu-item;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    padding: 4px 8px;
    border-radius: 4px;
    background: $color__brand;
    color: rgba($color__brand, .72);

    &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: $color__brand;
        margin-right: 8px;
    }

    &--in-stock {
        // default
    }

    &--low-stock,
    &--backorder {
        background: rgba($color__yellow, .08);
        color: rgba($color__yellow, .72);

        &:before {
            background: $color__yellow;
        }
    }

    &--out-of-stock {
        background: rgba($color__red, .08);
        color: rgba($color__red, .72);

        &:before {
            background: $color__red;
        }
    }
}
