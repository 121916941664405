.checkout_onepage_success .page-main,
.checkout-onepage-success .page-main {
    .page-title-wrapper {
        @include container-wide;
        margin: 32px auto;
    }

    .columns {
        @include container-wide;
    }
}
