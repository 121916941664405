@mixin product-item {
    position: relative;
    margin: 0 !important;
    width: auto;
    display: flex;
    overflow: hidden;
    height: 100%;
    padding: 12px 8px;
    border-radius: 8px;
    background: $color__white;
    box-shadow: 0 4px 18.7px 0 rgba(0, 94, 43, 0.25);

    a {
        text-decoration: none !important;
        //color: $color__black !important;
    }

    .product-label {
        @include text-menu-item;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 16px;
        background: $color__brand;
        color: white;
        z-index: 10;
        padding: 4px 8px;
        min-width: 88px;
        margin-bottom: 0;

        &--special-price {
            left: 0;
        }

        //&--good-price {
        //    right: 0;
        //    background-color: $color__brand;
        //}

        &--new {
            right: 0;
            background-color: $color__brand;
        }

        &--custom-label {
            left: 0;
            top: auto;
            bottom: 16px;
            max-width: 80%;
            background-color: $color__brand;
        }
    }

    .product-item-info {
        position: relative;
        border: none;
        width: 100%;
        display: flex;
        flex-direction: column;

        &:hover,
        &.active {
            box-shadow: none;
            margin: inherit;
            padding: 0;
            z-index: 9;
        }
    }

    .product-item-photo {
        position: relative;
        border-radius: 16px;
        overflow: hidden;
        margin: 0;
    }

    .product-image-container {
        width: 100%;
        overflow: hidden;

        display: block;
        position: relative;
    }

    .product-image-wrapper {
        height: auto;
    }

    .product-image-photo {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        //width: calc(100% - 24px);
        width: 100%;
        height: auto !important;
        aspect-ratio: 1;
        //margin: 8px auto 0;
        //border-bottom: 1px solid $color__brand;
    }

    .amasty-label-container {
        width: 96px !important;
        height: 48px !important;
    }

    //.product-item-actions {
    //    margin: 24px 0 0;
    //    display: flex;
    //    justify-content: center;
    //}

    .product-item-link {
        @include heading-product-title;
        position: static;
        margin-bottom: 16px !important;
        //height: 60px;
        //overflow: hidden;

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: 1;
        }
    }

    .actions {
        display: none;
    }

    .towishlist {
        display: none;
    }

    .product-item-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 24px 4px 0;
    }

    .product-item-sku {
        @include text-menu-item;
        color: $color__brand;
        margin-bottom: 0;
    }

    .minimal-price {
        .price-label {
            //display: none;
        }
    }

    .price-box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        flex: 1;

        p {
            margin-bottom: 8px;
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .price,
        .normal-price .price,
        .special-price .price {
            font-size: 16px;
            font-weight: 700;
            display: inline;
            color: $color__brand;
        }

        .price-from .price,
        .price-to .price {
            font-size: 16px;
            font-weight: 700;
            display: inline;
            color: $color__brand;
        }

        .special-price {
            .price-label {
                display: none;
            }
        }

        .old-price {
            //order: -1;

            .price-label {
                display: none;
            }

            span {
                font-size: 12px;
                font-weight: 700;
            }

            .price {
                color: $color__grey--4;
                text-decoration: line-through;
            }

            .price-tax-label {
                display: none !important;
            }

            .price-wrapper[data-label]:after {
                display: none;
            }
        }

        .price-tax-label {
            display: block;
            font-size: 0.8em;
            white-space: nowrap;
        }

        .price-wrapper {
            display: block;
        }

        .price-wrapper[data-label]:after {
            content: "(" attr(data-label) ")";
            display: inline-block;
            font-size: 0.8em;
            white-space: nowrap;
            padding-left: 1em;
        }
    }

    .price-box > .price-container,
    .price-box .special-price .price-container,
    .price-box .minimal-price .price-container,
    .stock.unavailable {
        display: block;
    }

    .stock.unavailable span {
        line-height: 48px !important;
    }

    .tocart {
        display: none;
    }
}
