.footer-social-wrapper {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;

  li {
    margin: 0 20px !important;

    a {
      font-size: 40px;
      display: block;
      margin: 0 !important;
    }
  }

  .social-item svg {
    fill: $color__white;
    display: block;

    rect {
      fill: $color__brand;
    }
  }
}