
$social__background-color: $color__brand !default;

[data-content-type="row"]:has(.accordion) {
    position: relative;

    &:before {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 346px;
        height: 754px;
        background-image: url(../images/chef.svg);
        background-repeat: repeat-y !important;
        background-position: center 0 !important;
    }

    @include media('>=laptop') {
        &:before {
            content: "";
        }
    }
}

.accordion,
.category-seo-attribute {
    @include container;
    margin-bottom: 128px;


    [data-content-type="heading"] {
        @include heading-h4;
        color: $color__grey--1;
        text-align: center;
        margin-bottom: 24px;
    }
}
