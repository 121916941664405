.contact-block {
    position: relative;
    margin-bottom: 64px;

    .pagebuilder-column-line {
        @include container-wide;
        position: relative;
        gap: 32px;


        &:before {
            content: "";
            background: $color__grey;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            pointer-events: none;
            z-index: -1;
        }
    }

    .contact-block__column {

        &--content {
            margin: 16px 0;
        }

        &--image {
            @include media ('<tablet_portrait') {
                height: 100%;
                order: -1;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                aspect-ratio: 1;
            }
        }
    }


    [data-content-type="heading"] {
        @include heading-h4;
        color: $color__brand;
    }

    &__form {

        fieldset {
            margin:0;
            padding:0;
            padding-bottom: 16px; //revert
        }
    }

    &__link-wrapper {
        padding-top: 8px;
    }

    &__link {
        margin-left: 16px;

        span {
            padding-left: 8px;
        }

        svg {
            vertical-align: middle;
        }

        &:last-child {
            border-left: 1px solid;
            padding-left: 16px;
        }
    }
}
