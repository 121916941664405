.widget-product-carousel {
    margin: 40px -16px 32px !important; // remove space on the sides
    padding: 0;

    .slick-track {
        // ensure equal height
        display: flex;
    }

    .slick-slide {
        height: auto;
    }

    .slick-slide > div {
        // space between slides
        padding: 16px 16px 24px;
        height: 100%;
    }

    .product-item {
        @include product-item;
        // smaller shadow to handle overflow in slider
        //box-shadow: 3px 5px 12px rgba(0, 0, 0, 0.04), 0 0 0 rgba(0, 0, 0, 0.04);
    }

    .slick-arrow {
        position: absolute;
        bottom: -40px;
        left: 50%;
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 4px;
        text-decoration: none;
        background: $color__brand;
        margin: 0 2px;
        text-indent: -99em;
        overflow: hidden;
        border: none;
        cursor: pointer;

        &:hover {
            background: $color__brand;
            color: $color__white;

            &:after {
                background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
            }
        }

        &:after {
            content: "";
            background-position: center center;
            background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
            background-repeat: no-repeat;
            background-size: 24px;
            height: 100%;
            width: 100%;
        }

        &.slick-prev {
            transform: translateX(-50px) rotate(90deg);
        }
        &.slick-next {
            transform: translateX(4px) rotate(-90deg);

        }
    }
}
