.product-reviews-summary {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    gap: 8px;


    .rating-summary {
        .label {
            display: none;
        }

        .rating-result {
            width: 80px;
            display: block;
            overflow: hidden;
            //padding-top: 4px;

            span {
                display: inline-block;
                height: 16px;
                background: $color__brand;
                mask-image: url('data:image/svg+xml; utf8, <svg width="80" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.551 3.371a.5.5 0 0 1 .897 0l.93 1.886a1.5 1.5 0 0 0 1.129.82l2.083.305a.5.5 0 0 1 .277.853L11.36 8.7a1.5 1.5 0 0 0-.432 1.329l.355 2.07a.5.5 0 0 1-.725.527l-1.861-.978a1.5 1.5 0 0 0-1.397 0l-1.86.979a.5.5 0 0 1-.726-.528l.355-2.07A1.5 1.5 0 0 0 4.638 8.7L3.133 7.235a.5.5 0 0 1 .276-.853l2.083-.305a1.5 1.5 0 0 0 1.128-.82l.931-1.886ZM23.551 3.371a.5.5 0 0 1 .897 0l.93 1.886a1.5 1.5 0 0 0 1.129.82l2.083.305a.5.5 0 0 1 .277.853L27.36 8.7a1.5 1.5 0 0 0-.432 1.329l.355 2.07a.5.5 0 0 1-.725.527l-1.861-.978a1.5 1.5 0 0 0-1.396 0l-1.861.979a.5.5 0 0 1-.726-.528l.355-2.07a1.5 1.5 0 0 0-.432-1.329l-1.505-1.466a.5.5 0 0 1 .276-.853l2.083-.305a1.5 1.5 0 0 0 1.128-.82l.931-1.886ZM39.551 3.371a.5.5 0 0 1 .897 0l.93 1.886a1.5 1.5 0 0 0 1.129.82l2.083.305a.5.5 0 0 1 .277.853L43.36 8.7a1.5 1.5 0 0 0-.432 1.329l.355 2.07a.5.5 0 0 1-.725.527l-1.861-.978a1.5 1.5 0 0 0-1.397 0l-1.86.979a.5.5 0 0 1-.726-.528l.355-2.07a1.5 1.5 0 0 0-.432-1.329l-1.505-1.466a.5.5 0 0 1 .276-.853l2.083-.305a1.5 1.5 0 0 0 1.129-.82l.93-1.886ZM55.551 3.371a.5.5 0 0 1 .897 0l.93 1.886a1.5 1.5 0 0 0 1.129.82l2.083.305a.5.5 0 0 1 .277.853L59.36 8.7a1.5 1.5 0 0 0-.432 1.329l.355 2.07a.5.5 0 0 1-.725.527l-1.861-.978a1.5 1.5 0 0 0-1.397 0l-1.86.979a.5.5 0 0 1-.726-.528l.355-2.07a1.5 1.5 0 0 0-.432-1.329l-1.505-1.466a.5.5 0 0 1 .276-.853l2.083-.305a1.5 1.5 0 0 0 1.129-.82l.93-1.886ZM71.551 3.371a.5.5 0 0 1 .897 0l.93 1.886a1.5 1.5 0 0 0 1.129.82l2.083.305a.5.5 0 0 1 .277.853L75.36 8.7a1.5 1.5 0 0 0-.432 1.329l.355 2.07a.5.5 0 0 1-.725.527l-1.861-.978a1.5 1.5 0 0 0-1.396 0l-1.861.979a.5.5 0 0 1-.726-.528l.355-2.07a1.5 1.5 0 0 0-.432-1.329l-1.505-1.466a.5.5 0 0 1 .276-.853l2.083-.305a1.5 1.5 0 0 0 1.129-.82l.93-1.886Z" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>');

                span {
                    display: none;
                }
            }
        }
    }

    .reviews-actions {
        .action.add {
            display: none;
        }

        a {
            color: rgba($color__black, .16) !important;
            font-size: 12px;
            text-decoration: none;
            margin-bottom: 0 !important;
        }
    }
}
