
.section-two-column {
    @include container-wide;
    margin-bottom: 64px;

    h1,h2,h3 {
        color: $color__brand;
    }

    .pagebuilder-column-group,
    .pagebuilder-column-line {
        gap: 32px;
        //margin-top: 32px;
    }

    .pagebuilder-button-primary {
        @include button();
        margin-top: 16px;
    }

}
