$text-style__heading-font: "Asap Condensed", sans-serif !default; // Regular, Bold
$text-style__text-font: "Asap Condensed", sans-serif !default; // Regular, Bold

$text-styles__heading-color: $color__grey--1 !default;
$text-styles__text-color: $color__grey--1 !default;
$text-styles__link-color: $color__grey--1 !default;

$text-style__margin: 0 0 16px 0;

@mixin heading {
    font-family: $text-style__heading-font;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
    margin: $text-style__margin;
    color: $text-styles__heading-color;
    -webkit-font-smoothing: antialiased;
    display: block;
    word-break: break-word;

    &:last-child {
        margin-bottom: 0;
    }
}

@mixin heading-h1 {
    @include heading;
    font-size: 40px;

    @include media('>tablet_portrait') {
        font-size: 72px;
        line-height: 96px;
    }
}

@mixin heading-h2 {
    @include heading;
    font-size: 32px;

    @include media('>tablet_portrait') {
        font-size: 56px;
        line-height: 72px;
    }
}

@mixin heading-h3 {
    @include heading;
    font-size: 24px;

    @include media('>tablet_portrait') {
        font-size: 48px;
        line-height: 56px;
    }
}

@mixin heading-h4 {
    @include heading;
    font-size: 20px;

    @include media('>tablet_portrait') {
        font-size: 32px;
        line-height: 40px;
    }
}

@mixin heading-h5 {
    @include heading;
    font-size: 18px;

    @include media('>tablet_portrait') {
        font-size: 24px;
        line-height: 32px;
    }
}

@mixin heading-product-title {
    @include heading;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $color__grey--1;
}

@mixin text {
    font-family: $text-style__text-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    margin: $text-style__margin;
    //-webkit-font-smoothing: antialiased;
    color: $text-styles__text-color;

    &:last-child {
        margin-bottom: 0;
    }
}

@mixin text-link {
    @include text;
    position: relative;
    display: inline-block;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
    color: $text-styles__link-color;

    &:hover {
        color: rgba($text-styles__link-color, .64);
        text-decoration: none;
    }
}

@mixin text-menu-item {
    @include heading;
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    text-transform: none;
}

@mixin text-label {
    @include text;
    color: $color__brand;
}

@mixin text-label-small {
    @include text;
    font-size: 12px;
    line-height: 16px;
    color: $color__brand;
}




