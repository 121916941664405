.newsletter-block {
    @include container-wide;
    margin-bottom: 0;
    padding-top: 88px;
    padding-bottom: 88px;

    .pagebuilder-column-group,
    .pagebuilder-column-line {
        gap: 32px;
    }

    [data-content-type="heading"] {
        @include heading-h3;
        color: $color__brand;
        width: 100%;
    }

    [data-content-type="image"] img {
        width: 100%;
    }

    [data-content-type="text"] {
        max-width: 352px;
    }


    #mc_embed_signup {
        max-width: 352px;
        margin-top: 24px;
    }

    .mc-field-group {
        strong {
            display: none;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            margin: 16px 0;
        }
    }

    input[type=submit] {
        @include button;
        @include button--contrast;
    }

    /**
     * Override default form element styling
     */

    input[type="text"], input[type="password"],
    input[type="tel"], input[type="email"],
    input[type="date"], input[type="datetime-local"],
    input[type="month"], input[type="week"],
    input[type="number"], input[type="url"],
    input[type="time"], input[type="search"]:not(.select2-search__field),
    textarea, select, .select2-container {
        background-color: transparent;
        border: 1px solid rgba($color__brand, .72);
    }

    label {
        color: $color__black;
        margin: 0 !important;

        .asterix {
            color: $color__form--error;
            font-size: 16px;
            margin: 0 0 0 4px;
            content: '*';
        }
    }
}
