.catalog-product-view {

    .page-title {
        @include heading-h3;
        color: $color__brand;
    }

    .catalog-product-top {
        @include container-wide;
        @include grid-standard(1);

        @include media('>=tablet') {
            @include grid-standard(2);
            display: flex;
            gap: 32px;
            align-items: center;

            > * {
                flex: 0 0 calc(50% - 16px);
                max-width: 50%;
            }
        }
    }

    .catalog-product-gallery {
        //max-height: 448px;

        @include media('>=tablet') {
        }
    }

    .catalog-product-aside {
        @include media('>=tablet') {
            //margin-top: 56px;
        }
    }

    .usp {
        @include media('<tablet_portrait') {
            display: none !important;
        }
    }

    .related,
    .upsell {
        @include container-wide;
        margin-bottom: 128px;

        [data-content-type="heading"],
        &__heading-item,
        .title {
            @include heading-h5;
            color: $color__grey--1;
            margin-bottom: 40px;
            text-align: center;
        }

        .products-grid .product-items {
            margin-bottom: 0 !important;

            @include media('>=tablet_portrait') {
                @include grid-standard(2);
            }

            @include media('>=tablet') {
                @include grid-standard(4);
            }
        }

        &__footer {
            display: flex;
            justify-content: center;
        }
    }
}
