
[data-content-type="row"]:has(.spots) {
    padding-top: 64px;
    padding-bottom: 64px;
    margin-bottom: 128px;
    background-image: linear-gradient(rgba($color__brand, 0.95), rgba($color__brand, 0.95)), url(../images/services.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.spots {
    @include container-wide;

    > [data-content-type="heading"] {
        @include heading-h2;
        color: $color__canvas;
    }

    > [data-content-type="text"] {
        p, span, a {
            color: $color__canvas !important;
            font-size: 16px !important;
            max-width: 544px;
            margin-inline: auto;
            margin-bottom: 0 !important;

            @include media('>tablet_portrait') {
                font-size: 20px !important;
            }
        }
    }

    .pagebuilder-column-group {
        margin-top: 32px;
    }

    .pagebuilder-column-line {
        gap: 32px;
    }

    .pagebuilder-column {
        box-shadow: 0 0 24px rgba(4, 7, 2, 0.16);
        position: relative;
        border-radius: 16px;
        overflow: hidden;

        [data-content-type="banner"] {
            background-color: $color__white;
            padding: 16px 32px;
            //min-height: 216px;

            h3 {
                @include heading-h4;
                color: $color__grey--1;
                margin-bottom: 8px;
            }

            a {
                display: block;
                text-decoration: none;
            }
        }

        .pagebuilder-button-primary {
            @include button();
            @include button--small();
            @include button--contrast();
            margin-top: 16px;
        }
    }

    //.pagebuilder-poster-content {
    //    display: flex;
    //    flex-direction: column;
    //
    //
    //    [data-element="content"] {
    //        flex: 1;
    //    }
    //}

    &__big {
        @include media('>=tablet_portrait') {
            padding-left: 30vw !important;
        }

        @include media('>=desktop') {
            padding-left: 480px !important;
        }
    }

}
