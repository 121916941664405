.cms-page-view {
    .page-main,
    .page-main-full-width {

        .page-title-wrapper {
            //@include container-wide;

            .page-title {
                @include container-narrow;
                width: 100%;
                margin-top: 32px;
                margin-bottom: 32px;
            }
        }

        .columns {
            //@include container-wide;
        }

        //[data-content-type="heading"] {
        //    @include container-narrow;
        //    width: 100%;
        //    margin-top: 16px;
        //}
        //
        //[data-content-type="text"] {
        //    @include container-narrow;
        //    width: 100%;
        //}
        //
        //[data-content-type="image"] img {
        //    display: inline-flex;
        //    max-width: 928px !important;
        //    width: 100%;
        //    margin: 32px auto;
        //}
    }
}
