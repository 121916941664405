.contact {
    position: relative;
    padding: 64px 0 128px;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1440px;
        background-image: url(../images/seo-bg.svg);
        background-repeat: repeat-y !important;
        background-position: center 0 !important;
        max-width: 100vw;

        @include media('<desktop') {
            background: none;
        }
    }

    fieldset {
        @include container-narrow;
        position: relative;
        background-color: transparent;

        @include media('<tablet_portrait') {
            padding: 0;
        }
    }

    br {
        display: none;
    }

    .legend {
        display: none;
    }

    .field.note {

    }

    .comment {
        grid-column: span 2;

        textarea {
            height: 160px;
        }
    }


    .actions-toolbar {
        display: flex;
        justify-content: center;
    }
}
