.accordion .komunikado-faq-item:last-of-type {
  border-bottom: none !important;
}

.komunikado-faq-item {
  border-bottom: 1px solid $color__brand;

  &__details {
    border-radius: 16px;
    background: transparent;
    margin: 16px 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 16px;
      //background-image: linear-gradient(180deg, #F89700 0%, #F89700 0.01%, #FCB240 100%);
      background-image: $color__white;
      opacity: 0;
      z-index: -1;
      transition: opacity 600ms;
      box-shadow: 0 4px 13.6px 0 rgba(0, 0, 0, 0.12);
    }

    &[open] {
      background: none;

      &:before {
        opacity: 1;
      }

      .komunikado-faq-item__summary {
        color: $color__canvas;
        background: $color__brand;

        &:before {
          rotate: 180deg;
        }
      }
    }
  }

  &__summary {
    @include heading-h5;
    margin-bottom: 0;
    list-style: none; // remove marker
    cursor: pointer;
    padding: 24px 64px 24px 24px;
    border-radius: 16px 16px 0 0;

    &::marker {
      display: none;
    }

    // Safari
    &::-webkit-details-marker {
      display: none;
    }

    &:before {
      content: "";
      background-image: url('data:image/svg+xml; utf8, <svg width="40" height="39" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="39" rx="19.5" fill="url(%23a)"/><path d="m14 17 5.24 6.114a1 1 0 0 0 1.52 0L26 17" stroke="%23FFFCF9" stroke-width="4" stroke-linecap="round"/><defs><linearGradient id="a" x1="20" y1="0" x2="20" y2="39" gradientUnits="userSpaceOnUse"><stop stop-color="%23629965"/><stop offset="1" stop-color="%23629965"/></linearGradient></defs></svg>');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 20px;
      right: 24px;
      width: 40px;
      height: 40px;
      transition: rotate 600ms;
      rotate: 0deg;
    }
  }

  &__content {
    padding: 24px;

    //&:before {
    //  content: "";
    //  border-bottom: 1px solid $color__canvas;
    //  padding: 0;
    //  margin: 40px 0;
    //  display: block;
    //}

    //&, p, a {
    //  color: $color__canvas;
    //}
  }
}