
$minicart__counter-background: $color__brand !default;

.minicart-wrapper {
    position: relative;

    .action.showcart {
        display: flex !important;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: 0;

        &:before {
            // added styling to critical/_header.scss
        }

        .counter.qty {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            line-height: 16px;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px;
            font-weight: normal;
            border-radius: 50%;
            margin: 0;
            padding: 0;
            background: $minicart__counter-background;
            color: $color__white;

            &.empty {
                display: none;
            }
        }

        .counter-number {
            text-shadow: none;
        }

        .counter-label {
            display: none;
        }

        .text {
            display: none;
        }
    }

    .ui-dialog {
        min-width: 351px; // match iPhone

        @include media('>=tablet_portrait') {
            top: calc(100% + 16px);
            right: 0;
            margin: 0;
            min-width: 466px; // match iPhone
        }

        &:after {
            @include media('>=tablet_portrait') {
                content: "";
                position: absolute;
                top: -8px;
                right: 12px;
                background: $color__white;
                width: 16px;
                height: 16px;
                transform: rotate(45deg);
                border-radius: 2px;
                border: none;
            }
        }

        .close {
            display: none;
        }
    }

    .block-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 0 16px;
    }

    .block-title {
        @include heading-h4;
        width: 100%;
        text-align: center;
    }

    ol {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .items-total {
        display: none;
    }

    .subtitle {
        @include text;
        text-align: center;
        width: 100%;
        margin: 0;

        &:not(.empty) {
            display: none !important;
        }
    }

    .subtotal {
        @include heading-h4;
        width: 100%;
        order: 9;
        margin-top: 24px;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        padding-top: 12px;
        border-top: 1px solid $color__black;

        .label {
            //font-weight: bold;
        }

        + .actions {
            order: 11 !important; // turn around the button order
        }
    }

    .block-content > .actions {
        display: flex;
        justify-content: center;
        order: 10;
    }

    .action.checkout,
    .action.viewcart {
        @include button;
    }

    .action.viewcart {
        @include button--white;
        border-color: $color__brand;
        min-width: 120px;
    }

    .product.actions {
        display: flex;
        justify-content: space-between;
    }

    .product.options {
        display: none;
    }

    .qty {
        display: none;
    }

    .action.edit {
        display: none;
    }
}
