//
// ----------------------------------------
// BREAKPOINTS
//

$breakpoints: (
  phone: 375px,
  tablet_portrait: 768px,
  tablet: 1024px,
  laptop: 1280px,
  desktop: 1440px
);

body::before {
  content: '#{$breakpoints}';
  display: none; }

// How it works?
//
// $breakpoints is variable for include-media mixins http://include-media.com/
// They are loaded by default from __vendors/_include-media.scss
//
// You can name breakpoint as you want. You can also add as many breakpoints you need.
// For example you can define such breakpoints:
//
// > $breakpoints: (xs: 320px, sm: 768px, md: 1024px, lg: 1200px, xl: 1400px);
//
// Then, you can include them:
//
// > @include media('>=sm') { /* CSS code here */ }
//
// It gonna compile it to:
//
// > @media (min-width: 768px) { /* CSS code here */ }
//
// Default set of breakpoints are inspired by Bootstrap 4 breakpoints:
// https://v4-alpha.getbootstrap.com/layout/grid/#grid-options
//
// Important! When you declare media higher than a breakpoint, do it with equal sign like:
//
// > @include media('>=lg') { /* CSS code here */ }
//
// When you declare media lower than a breakpoint, do it without equal sign like:
//
// > @include media('<lg') { /* CSS code here */ }
//
// Of course more appreciated is declaring only >= values, mobile first.
//
// Breakpoints are deliver to js/__constants/breakpoints.js in body::before pseudoelement



//@mixin override--no-responsive-padding {
//  @include for-small-desktop-down {
//    padding: 0;
//  }
//}

// @see https://stackoverflow.com/questions/46313640/iphone-x-8-8-plus-css-media-queries
@mixin iphone-x {
  /* iphone X , XS, 11 Pro */
  @media only screen
  and (min-device-width: 375px)
  and (max-device-height: 812px)
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: portrait) {
    @content
  }
}

@mixin iphone-xr {
  /* iphone XR, 11 */
  @media only screen
  and (min-device-width: 414px)
  and (max-device-height: 896px)
  and (-webkit-device-pixel-ratio: 2)
  and (orientation: portrait) {
    @content
  }
}

@mixin iphone-max {
  /* iphone XS Max, 11 Pro Max */
  @media only screen
  and (min-device-width : 414px)
  and (max-device-height : 896px)
  and (-webkit-device-pixel-ratio : 3) {
    @content
  }
}

@mixin new-gen-iphones {
  @include iphone-x {
    @content
  }

  @include iphone-xr {
    @content
  }

  @include iphone-max {
    @content
  }
}
