.wishlist-index-index .page-wrapper {
    .products-grid .product-items {
        @include grid-standard(1);
        padding: 0;

        @include media('>=tablet_portrait') {
            @include grid-standard(2);
        }

        @include media('>=tablet') {
            @include grid-standard(3);
        }
    }

    .product-item-tooltip,
    .product-item-inner {
        padding: 24px;
    }

    .box-tocart {
        display: none;
    }

    .product-item-actions {
        display: flex;
        justify-content: space-between;
    }

    .actions-toolbar {
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        @include media('<tablet_portrait') {
            flex-direction: column;
        }

        .primary {
            gap: 8px;

            @include media('<tablet_portrait') {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .action {
                margin: 0;

                @include media('>=tablet_portrait') {
                    margin: 8px 40px 8px 0;
                }
            }
        }
    }

    .action.tocart {
        display: none;
    }
}

