.page-header {
    //background: $color__white;
    //box-shadow: 0 0 16px #E6F0EF;

    .panel.wrapper {
        border-bottom: 1px solid $color__brand;

        .block {
            @include container-wide;
            margin-bottom: 0;
            padding-top: 8px;
            padding-bottom: 8px;

            p, a {
                font-size: 12px;
                line-height: 1;
            }
        }
    }

    .header.content {
        @include container-wide;
        height: 72px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 0;

        @include media('>tablet') {
            justify-content: space-between;
        }

        @include media('>=laptop') {
            height: 88px;
        }

        &:before,
        &:after {
            content: unset !important;
        }
    }

    .logo {
        height: 64px;
        order: 1;
        margin: 0;

        @include media('<=tablet') {
            margin-right: auto;
            height: 40px;
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    .nav-sections {
        order: 2;
        flex: 1;
    }

    .block-search {
        order: 2;
    }

    .minicart-wrapper {
        order: 3;
    }

    .user-actions-wrapper {
        order: 4;
    }

    .nav-toggle {
        order: 5;
    }


}
