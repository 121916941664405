.customer-account-createpassword .page-main {
    .page-title-wrapper {
        @include container-wide;
        margin: 32px auto;
    }

    .columns {
        @include container-wide;
    }

    .actions-toolbar {

    }
}
