/*Vendors*/
@import "vendors/normalize";
@import "vendors/include-media";
//
@import "_breakpoints";
@import "_base/*";

// TODO: remove when Critical CSS module is setup
//@import "critical/critical-hidden";
//@import "critical/critical-cls";


@import "_global";

.page-wrapper {
  @import "_atoms/*";
  @import "_molecules/**/*";
  //@import "_organisms/*";
  @import "_organisms/global/*";
  @import "_organisms/catalog/*";
  @import "_organisms/cms-builder/*";
}

@import "_templates/*";
