.shipping-address-items {

    .shipping-address-item {
        background: rgba($color__black, .04);
        margin: 16px 0;
        padding: 32px;
    }

    .selected-item {
        border-radius: 16px;
        box-shadow: 0 0 2px 0 $color__brand;
    }
}
