body.account .page-main {

    .page-title-wrapper {
        margin: 32px auto;
    }

    .columns {
        @include container-wide;

        @include media('>=tablet_portrait') {
            @include grid-standard(4);
        }
    }

    .column.main {
        @include media('>=tablet_portrait') {
            @include grid-column(3, 2);
            grid-row: 1 / span 4;
        }
    }

    .sidebar-main {
        margin-top: 64px;

        @include media('>=tablet_portrait') {
            @include grid-column(1);
            grid-row: 1;
        }
    }

    .sidebar-additional {
        @include media('>=tablet_portrait') {
            @include grid-column(1);
            grid-row: 2;
        }
    }

    .block-collapsible-nav-content {
        ul {
            list-style: none;
            margin: 0 0 32px;
            padding: 0;
        }

        li {
            margin-bottom: 0;
        }

        a {
            padding: 4px 0;
        }
    }

    .box {
        margin-bottom: 32px;
    }

    .box-title {
        display: block;
        margin-bottom: 4px;
    }

    .actions-toolbar {
        margin-top: 32px;
    }
}
