.pages {
    order: 100;

    .label {
        display: none !important;
    }

    .items {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .item {
        margin: 0 2px;

        a:hover,
        &.current a,
        &.current strong, {
            background: $color__brand;
            color: $color__white;
        }

        a, strong {
            display: flex;
            width: 24px;
            height: 24px;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 100%;
            text-decoration: none;
            background: $color__grey;

            @include media('>=tablet_portrait') {
                width: 40px;
                height: 40px;
            }
        }
    }

    .pages-item-next,
    .pages-item-previous {
        a:after {
            content: "";
            background-position: center center;
            background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
            background-repeat: no-repeat;
            background-size: 24px;
            height: 100%;
            width: 100%;
        }

        span {
            display: none;
        }
    }

    .pages-item-next a:after {
        transform: rotate(-90deg);
    }
    .pages-item-previous a:after {
        transform: rotate(90deg);

    }
}
