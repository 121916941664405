
$form__legend-color: $color__black !default;
$form__control-color: $color__brand !default;

@mixin placeholder($_color) {
    &::-webkit-input-placeholder { /* Edge */
        color: $_color;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $_color;
    }
    &::placeholder {
        color: $_color;
    }
}

& {
    @include placeholder(rgba($color__black, .24));
}

// @see https://medium.com/@rion.mrk/how-to-remove-x-icon-from-search-input-field-or-input-type-search-db3c808405fb
/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.form-disabled,
.form-disabled .select2-selection {
    cursor: not-allowed;
}

.form-item {
    margin-bottom: 16px;
}

input[type="text"], input[type="password"],
input[type="tel"], input[type="email"],
input[type="date"], input[type="datetime-local"],
input[type="month"], input[type="week"],
input[type="number"], input[type="url"],
input[type="time"], input[type="search"]:not(.select2-search__field),
textarea, select, .select2-container {
    @include text;
    display: block;
    width: 100%;
    min-width: 120px;
    min-height: 48px;
    background-color: $color__grey;
    border: none;
    color: $color__black;
    padding: 0 16px;
    outline: none;

    &.mage-error {
        border: 1px dashed $color__form--error;
    }

    &:focus {
        outline: none;
    }

    span {
        outline: none;

        &:focus {
            outline: none;
        }
    }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    @include media('>=tablet_portrait') {
        opacity: 1;
        padding-right: 4px;
    }
}

fieldset {
    border: none;
    margin: 32px 0;
    padding: 16px;
    background: rgba($color__grey,0.16);

    &:empty {
        display: none;
    }

    legend {
        @include heading-h3;
        color: $form__legend-color;
        position: relative;
        top: 24px;
    }
}

textarea {
    padding: 16px;
}

select {
    width: 100%;
    max-width: 100%;
    appearance: none;
    background-position: calc(100% - 12px) calc(50% + 1px);
    background-image: url('data:image/svg+xml; utf8, <svg width="10" height="6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1 5 5 1 1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-repeat: no-repeat;
    background-size: 8px;
    cursor: pointer;
    padding-right: 40px;
}

.form-actions {
    margin: 0 !important;
}

label {
    @include text-label;
    margin: 0;
    display: inline-flex;
    cursor: pointer;
}

input[type="checkbox"]:not(.required-captcha),
input[type="radio"] {
    --input-size: 16px;
    appearance: none;
    position: relative;
    height: var(--input-size);
    width: var(--input-size);
    background: rgba($color__grey, .4);
    border: 1px solid rgba($color__brand, .4);
    color: $color__white;
    cursor: pointer;
    display: inline-block;
    outline: none;
    margin: 0 8px 0 0;
    transition: all 0.15s ease-out 0s;
    flex: 0 0 var(--input-size);

    &:hover {
        border-color: $form__control-color;
        border-style: solid;
    }

    &:checked {
        background: $form__control-color !important;
        border-color: $form__control-color !important;
        border-style: solid;
    }

    &.mage-error {
        border: 1px dashed $color__form--error;

        label {
            &:before {
                color: $color__form--error;
            }
        }
    }

    + label {
        @include text;
        text-transform: none;
    }
}

input[type="checkbox"] {
    &:checked {
        &:before {
            height: 64%;
            width: 24%;
            position: absolute;
            content: '';
            border-bottom: 2px solid $color__white;
            border-right: 2px solid $color__white;
            transform: translate(-50%, -50%) rotate(45deg);
            left: 50%;
            top: 40%;
        }
    }
}

input[type="radio"] {
    border-radius: 50%;

    &:checked {
        &:before {
            content: "";
            line-height: var(--input-size);
            background: $color__white;
            position: absolute;
            top: 32%;
            left: 32%;
            bottom: 32%;
            right: 32%;
            border-radius: 50%;
        }
    }
}


.field.required,
.field._required {
    .label:after {
        color: $color__form--error;
        font-size: 16px;
        margin: 0 0 0 4px;
        content: '*';
    }
}

.field._error input {
    border-style: dashed;
    border-color: $color__form--error;
}

div.field-error,
div.mage-error {
    color: $color__form--error;
    padding: 0;
    position: relative;
    top: -12px;
}

.field.choice {
    display: flex;

    input {
        flex-shrink: 0;
        margin-top: 4px;
    }

    label {
        margin-top: 0;
        flex-grow: 1;
    }
}
