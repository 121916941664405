.footer-quicklinks {
    display: grid;
    grid-template-columns: 2fr auto;
    grid-column: span 12;

    * {
        color: $color__white;
        font-weight: bold;
        margin: 0;
    }

    .block.widget {
        // Logo
        margin: 0;

        img {
            height: 46px;
        }
    }

    .block.ninjamenus-widget {
        margin: 0 0 0 auto;

        .ninjamenus {
            .nav-item {
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    top: 8px;
                    bottom: 8px;
                    right: 0;
                    display: block;
                    width: 1px;
                    background-color: $color__white;
                }

                &:last-child:after {
                    content: unset;
                }
            }

            a {
                font-weight: normal;
                text-decoration: none;
                padding: 8px 16px;

                &:hover {
                    text-decoration: underline;
                    color: $color__white;
                }

            }
        }

        .ninjamenus-drilldown {
            border: none !important;
        }

        .drilldown-root {
            margin: 0;
            height:100%
        }

        .magezon-builder {
            flex-direction: row !important;
        }
    }
}
