
$social__background-color: $color__brand !default;

.social {
    @include container;
    padding: 88px 0;
    position: relative;
    margin-bottom: 128px;
    border-radius: 24px;
    box-shadow: 0 4px 19.4px 0 rgba(1, 77, 36, 0.63);
    text-align: center;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        inset: 0;
        background: $social__background-color;
        opacity: 0.95;
    }

    > div,
    &__content {
        @include container-narrow;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 1;
        //gap: 16px;
        max-width: 392px;

        * {
            color: $color__canvas !important;
            font-size: 16px !important;
            line-height: 20px !important;

            @include media('>tablet_portrait') {
                font-size: 20px !important;
                line-height: 32px !important;
            }
        }
    }

    svg {
        width: 48px;
        fill: $color__canvas;
    }

    [data-content-type="text"]:not(:last-child) {
        margin-bottom: 48px;
    }

    [data-content-type="heading"] {
        @include heading-h4;
        color: $color__canvas;
        position: relative;
    }

    .pagebuilder-button-primary {
        @include button;
        @include button--contrast;
    }
}
