.filter {
    .filter-title {
        display: none;
    }

    //.filter-actions,
    .filter-subtitle,
    .filter-current {
        display: none;
    }

    dl.options {
        dt {
            margin-bottom: 16px;
        }

        dd {
            margin: 0;
        }

        ol.items {
            padding: 0;
        }

        li.item {
            list-style: none;
        }
    }

    .filter-options {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    .filter-options-item {
        position: relative;
        margin-inline-end: 30px;
        min-width: 212px;
        border: 1px solid $color__grey; // #d9d7d7;
        padding: 0;
        border-radius: 4px;
        margin: 0; // 15px 0 0;
        background-color: $color__grey;
    }

    .filter-options-title {
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        position: relative;
        min-height: 40px;
        display: flex;
        align-items: center;
        padding-left: 12px;

        &:after {
            content: "";
            position: absolute;
            top: 12px; // 8px;
            right: 8px; // 16px;
            display: block;
            width: 16px;
            height: 16px;
            background-image: url('data:image/svg+xml; utf8, <svg width="12" height="8" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity=".64" d="M11 1 6 6 1 1" stroke="%23040702" stroke-width="2"/></svg>');
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .filter-options-content {
        min-width: 380px;
        position: absolute;
        left: 0;
        z-index: 2;
        margin-top: 5px;
        padding: 15px;
        background: $color__grey; //#f7f7f7;
        box-shadow: 0 4px 8px rgba($color__black,0.1);

        input[type="checkbox"] {
            float:left;
        }
    }

    .swatch-option.image {
        box-shadow: inset 1px 2px 3px rgba($color__black, 0.16)
    }
}
