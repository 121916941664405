

//Only used for portrait down currently, may need to be expanded
.overflow-hidden {
  @include media('<tablet') {
    overflow: hidden;
  }
}

//If HTML has overflow hidden, prevent body from scrolling as well --- fix for IOS
html.overflow-hidden {
  body {
    overflow: hidden;
  }
}
body._has-modal {
    overflow: hidden;
    height: 100%;
    width: 100%;
}

body {
//  overflow: hidden;
//  max-width: 100vw;
  margin: 0;
  padding: 0;
}

.loading-mask {
    //display: none !important;
    position: fixed;
    inset: 0;
    background: rgba(255,255,255,.5);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;

    p {
        display: none;
    }
}

[data-role=main-css-loader] {
    display: none;
}

.page-main {
  //@include container-wide;
  //margin: 0 auto !important;
  //padding: 0 !important;
}

.ui-dialog {
    display: none;
}

/* Page Builder */
@include media('<tablet_portrait') {
    .pagebuilder-mobile-hidden {
        // now we use webp !
        //display: none !important;
    }

    .pagebuilder-column-group,
    .pagebuilder-column-line {
        flex-direction: column;
    }

    .pagebuilder-column {
        width: 100% !important;
    }
}

// now we use webp !
//@include media('>tablet_portrait') {
.pagebuilder-mobile-only {
    display: none !important;
}
//}
