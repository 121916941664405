.catalog-category-view {
    //.toolbar {
    //    @include container-wide;
    //}
    //
    //.products-grid {
    //    @include container-wide;
    //    margin-bottom: 96px;
    //}
    //
    //#amasty-shopby-product-list {
    //    position: relative;
    //}
}

body.catalog-category-view .page-main {

    .page-title-wrapper {
        margin: 32px auto;
    }

    .columns {
        @include container-wide;
        display: flex;
        flex-wrap: wrap;

        @include media('>=tablet_portrait') {
            @include grid-standard(4);
        }
    }

    .column.main {
        order: 1;

        @include media('>=tablet_portrait') {
            @include grid-column(3, 2);
            grid-row: 1 / span 4;
        }
    }

    .sidebar-main {
        margin-top: 32px;
        margin-bottom: 32px;
        order: 0;

        @include media('>=tablet_portrait') {
            @include grid-column(1);
            grid-row: 1;
        }
    }

    .sidebar-additional {
        @include media('>=tablet_portrait') {
            @include grid-column(1);
            grid-row: 2;
        }
    }

    .block-collapsible-nav-content {
        ul {
            list-style: none;
            margin: 0 0 32px;
            padding: 0;
        }

        li {
            margin-bottom: 0;
        }

        a {
            padding: 4px 0;
        }
    }

    .box {
        margin-bottom: 32px;
    }

    .box-title {
        display: block;
        margin-bottom: 4px;
    }

    .actions-toolbar {
        margin-top: 32px;
    }
}
