.products-grid {
    a {
        text-decoration: none !important;
        color: $color__black !important;
    }

    .product-items {
        @include grid-standard(2);
        padding: 0;
        margin-bottom: 32px;

        @include media('>=tablet_portrait') {
            @include grid-standard(2);
        }

        //@include media('>=tablet') {
        //    @include grid-standard(3);
        //}

        @include media('>=laptop') {
            @include grid-standard(3);
        }
    }

    .product-item {
        @include product-item;
    }

}
