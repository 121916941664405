.footer-bottom {
    position: relative;
    margin-top: 64px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;

    @include media('>=tablet') {
        flex-direction: row;
        margin-bottom: 0;
        gap: 0 32px;
        align-items: flex-end;
    }

    > div {
        flex: 1 0 40%;
        margin-bottom: 0;
    }

    ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
    }

    li {
        margin: 0 20px;

        a {
            font-size: 40px;
        }
    }

    img {
        display: inline-block;

        @include media('<tablet_portrait') {
            margin-bottom: 8px;
        }
    }
}
