#opc-sidebar .opc-block-summary {
    position: relative;
    border-radius: 16px;
    background: $color__white;
    box-shadow: 0 4px 4.9px 0 rgba(1, 83, 39, 0.12);
    overflow: hidden;
    margin-bottom: 32px;
    padding: 24px;

   > .title {
        @include heading-h4;

        &:before {
            content: "✓";
            background: $color__brand !important;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            vertical-align: middle;
            text-align: center;
            color: $color__white;
            font-size: 20px;
            font-weight: 400;
            box-shadow: none;
            position: static;
            border: none;
            margin: -4px 8px 0 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }

    .details-qty {
        margin-bottom: 8px;
    }
}
