@import "../_atoms/button";

.amasty_quote-account-view {
    .order-status {
        display: none;
    }

    .order-actions-toolbar .actions {
        a {
            @include button();
        }

        a:last-child {
            @include button--white;
        }
    }
}
