
.product-info-main {
    position: relative;
    margin-bottom: 16px;

    .product.alert.stock a {
        @include button();
    }
}

.product-add-form {
    margin-top: 48px;

    .options-list {
        display: flex;
        flex-direction: column;
        //flex-wrap: wrap;
        gap: 0 32px;
        margin-bottom: 32px;
        padding: 0;

        .field.choice {
            display: flex;
            align-items: flex-start;
            margin-bottom: 8px;

            input {
                flex: 0 0 auto;
            }

            label {
                flex: 0 1 auto;
                display: block;
            }
        }
    }

    .action.tocart {
        @include button();
        width: auto;
        margin-bottom: 0 !important;
        flex: 1;
        max-width: 160px;

        &:before {
            //content: url('../images/cart.svg');
            content: url('data:image/svg+xml; utf8, <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 14.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334ZM13.333 14.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334ZM.667.667h2.666L5.12 9.593a1.333 1.333 0 0 0 1.333 1.074h6.48a1.333 1.333 0 0 0 1.334-1.074L15.333 4H4" stroke="%23fff" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            display: inline-block;
            margin-right: 8px;
        }
    }

    .product-options-bottom .fieldset,
    .product-options-bottom .fieldset .field {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin: 0;
    }
}


.box-tocart {
    //margin: 80px 0 0;

    .input-text.qty {
        width: 72px;
        //margin: 0 16px 0 8px;
    }

    .action.tocart {
        margin: 0 !important;
        white-space: nowrap;
        width: auto;
    }
}

.product-info-price {
    margin: 24px 0;

    .price-box,
    .minimal-price {
        margin-bottom: 32px;
        display: flex;
        flex-direction: column-reverse;

        .special-price .price-label {
            display: none;
        }

        .price,
        .normal-price .price,
        .special-price .price {
            @include heading-h3;
            display: inline;
            color: $color__brand;
        }

        .old-price {
            span {
                font-size: 16px;
                font-weight: normal;
            }

            .price {
                color: $color__grey--4;
                text-decoration: line-through;
                margin-left: 4px;
            }
        }


        .price-wrapper[data-label]:after {
            content: "(" attr(data-label) ")";
            display: inline-block;
            font-size: 0.8em;
            white-space: nowrap;

            padding-left: 8px;
        }
    }

}

.product-options-bottom {
    margin-top: 32px;

    .product-info-price {
        margin: 0 auto;
        text-align: center;
    }
}
