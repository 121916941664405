
.navigation > .ninjamenus.ninjamenus-desktop {
  @include container-wide;
  margin-bottom: 0;
  position: static;

  &:before,
  &:after {
    content: unset;
  }

  .nav-item > a {
    @include text-menu-item;
    padding: 0;
    margin: 0;
  }

  > .magezon-builder {
    display: flex;
    margin: 0;
    //white-space: nowrap;
    gap: 40px;
  }

  .magezon-builder {
    &:before,
    &:after {
      content: unset;
    }
  }

  .level0 {
    float: none;
    //position: static;

    > a {
      @include text-menu-item;
      line-height: 64px;
      margin-bottom: 0;
      background: transparent !important;
      color: $color__white !important;

      .caret {
        display: none !important;
      }
    }

    &:hover {
      > a {
        &, span {
          text-decoration: underline;
        }
      }
    }

    //&.active {
    //  > a {
    //    &, span {
    //      font-weight: normal;
    //    }
    //  }
    //}

    &.left_edge_menu_bar {
      position: static;

      > .item-submenu {
        //@include container-wide;
        padding-top: 48px;
        padding-bottom: 48px;
        position: absolute;
        left: 0;
        right: 0;
      }

      .inner-content {
        //@include grid-standard(4);
      }
    }

    &.left_edge_parent_item {
      > .item-submenu {
        position: absolute;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
      }

      .inner-content {
        //@include grid-standard(4);
        grid-gap: 32px 64px;
      }
    }

    // level1
    > .item-submenu {
      padding: 32px 32px;
      background: $color__white;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
      width: auto;

      .nav-item {
        display: block;
        margin-bottom: 16px;

        .nav-item {
          margin-bottom: 0;
        }

        a {
          @include text-menu-item;
          position: relative;
          display: inline-block;
          color: $color__black;
          font-weight: 600;
          margin-bottom: 0;
          line-height: 28px;

          &:hover span {
            text-decoration: underline !important;
          }
        }
      }
    }

    // level2
    > .item-submenu .item-submenu {
      .nav-item a {
        @include text-menu-item;
        margin-bottom: 0;
        font-weight: 400;
        color: $color__grey--1;

      }
    }
  }
}
