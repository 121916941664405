.checkout-cart-index .page-main {

    .page-title-wrapper {
        @include container-wide;
        margin: 32px auto;
    }

    .columns {
        @include container-wide;
    }

    .cart-container {
        @include grid-standard(1);

        @include media('>=tablet_portrait') {
            @include grid-standard(4);
        }

        .cart-totals {
            margin: 0 -8px;
        }

        .cart-summary {
            padding: 16px;
            background: rgba($color__black, .04);

            @include media('>=tablet_portrait') {
                @include grid-column(1, 4);
                grid-row: -1;
            }

            th {
                text-align: left;
                padding: 8px;
            }

            td {
                text-align: right;
                padding: 8px;
            }

            .table.totals {
                margin-bottom: 32px;
            }

            .title {
              @include heading-h4;
            }

            .methods {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }

        > *:not(.cart-summary) {
            @include media('>=tablet_portrait') {
                @include grid-column(3, 1);
            }
        }

        #block-shipping {
            display: none;
        }

        .items {
            thead tr th {
                @include heading-product-title;
                border-bottom: 1px solid $color__black;
                display: none;
            }

            tbody tr td {
                display: flex;
            }

            thead tr th,
            tbody tr td {
                padding: 16px;
                flex-direction: column;

                @include media('>=tablet_portrait') {
                    display: table-cell;
                    text-align: right;
                }

                &.col.item {
                    text-align: left;
                }
                &.col.qty {
                    .label {
                        display: none;
                    }

                    input {
                        min-width: auto;
                        width: 100px;
                        text-align: center;
                    }
                }
            }

            .item-info {
                th, td {
                    @include media('<tablet_portrait') {
                        &:before {
                            @include heading-h3;
                            content: attr(data-th);
                        }
                    }
                }
            }

            .actions-toolbar {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 32px;

                a {
                    margin: 0;
                }
            }

            [data-label]:after {
                content: "(" attr(data-label) ")";
                display: block;
                font-size: 0.8em;
                white-space: nowrap;
            }
        }


        .cart.main.actions {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px 0;
            border-top: 1px solid $color__black;
            border-bottom: 1px solid $color__black;
            margin: 16px 0;
            flex-wrap: wrap;
            gap: 16px 32px;

            @include media('>=tablet_portrait') {
                justify-content: space-between;
            }

            a, button {
                margin-bottom: 0 !important;
            }
        }
    }

    .discount {
        .title {
            @include button;
            @include button--small;
            @include button--contrast;
        }
    }

    .crosssell {
        [data-content-type="heading"],
        &__heading-item,
        .title {
            @include heading-h5;
            color: $color__grey--1;
            margin-bottom: 40px;
            text-align: center;
        }
    }
}
