.category-categories {
    @include container-wide();
    margin-bottom: 128px;

    &__list {
        @include grid-standard(2);

        @include media('>=tablet_portrait') {
            @include grid-standard(2);
        }

        @include media('>=tablet') {
            @include grid-standard(3);
        }

        @include media('>=laptop') {
            @include grid-standard(4);
        }
    }

    &__title {
        @include heading-h3;
        color: $color__brand;
    }

    &__category {

    }

    .category-card {
        position: relative;
        margin: 0 !important;
        width: auto;
        display: flex;
        flex-direction: column;
        background: $color__white;
        box-shadow: 0 0 24px rgba(4, 7, 2, 0.16);
        overflow: hidden;
        text-align: center;
        text-decoration: none;

        &__header {
            aspect-ratio: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__body {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin: 24px;
        }

        &__title {
            @include text-menu-item;
            color: $color__brand;
            margin-bottom: 0;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
