
.navigation {
    background: $color__white;
    margin-bottom: 0;

    @include media('>tablet') {
        background: $color__brand;
    }

    .nav-item {
        display: inline-block;
        position: relative;
    }
}
