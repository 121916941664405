.table-totals {
    //margin: 32px -8px;
    min-width: 100%;
    width: auto;

    th {
        @include text;
        text-align: left;
        //padding: 8px;
    }

    td {
        @include text;
        text-align: right;
        //padding: 8px;
    }

    tr.totals .value {
        display: block;
        color: rgba(0, 0, 0, .56);
    }

    tr.totals.sub {
        th, td {
            border-top: 1px solid $color__brand;
            padding: 16px 0 0 !important;
        }
    }

    tr.totals-tax {
        th, td {
            padding-bottom: 16px;
        }
    }

    tr.grand.totals.incl {
        th, td {
            border-top: 1px solid $color__brand;
            padding: 16px 0 0 !important;
        }
    }
}
