.rewards__checkout-cart-usepoints {

    .discount-form .label--checkbox b {
        margin-left: 4px;
        font-weight: bold;
    }

    .onestepcheckout-newsletter {
        display: flex;
        align-items: center;
        margin-top: 8px;
    }
}
