.payment-methods {
    //margin: 32px auto;

    .payment-method-title {
        padding: 0 !important;
    }

    .actions-toolbar {
        display: none !important;
    }

    label {
        display: flex;
        flex-direction: column;
    }

    span {
        width: 100%;
        //margin-bottom: 8px;
    }

    img {
        max-width: 48px;
        display: inline-block;
    }

    .payment-method {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        //background: $color__brand;
        //margin: 16px 0;
        //padding: 32px;
        //cursor: pointer;
        border-bottom: 1px solid $color__brand;
        padding: 16px 0;

        &:last-child {
            border-bottom: none;
        }

        &._active .payment-method-content {
            display: block !important;
        }
    }

    .payment-method-content {
        display: none;
        margin-top: 8px;
        padding: 8px 24px !important;

        label {
            display: inline-block !important;
        }
    }
}
