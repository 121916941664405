.product.media {
    position: relative;
    margin-bottom: 16px;

    .fotorama__stage__frame .fotorama__img {
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        object-fit: contain;

        &--full {
            // if fotorama__img is object-fit: contain, the --full image peeks out behind it
            display: none;
        }

        @supports (aspect-ratio: 1) {
            aspect-ratio: 1;
            height: auto;
        }
    }

    .fotorama__thumb-border {
        display: none;
    }

    .fotorama__nav__frame.fotorama__active .fotorama__thumb {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            inset: 0;
            left: auto;
            width: 2px;
            background: $color__brand;
            pointer-events: none;
        }
    }

    .fotorama__thumb {
        object-fit: cover;
        opacity: .48;
        cursor: pointer;
        transition: 300ms linear opacity;
    }

    .fotorama__active .fotorama__thumb,
    .fotorama__thumb:hover {
        opacity: 1;
    }

    .fotorama__stage.fotorama__shadows--right:after,
    .fotorama__nav.fotorama__shadows--right:after,
    .fotorama__stage.fotorama__shadows--left:before,
    .fotorama__nav.fotorama__shadows--left:before {
        opacity: .2;
    }

    .fotorama__nav--dots {
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
    }

    .fotorama__nav__frame--dot {
        width: 24px;
        height: 24px;

        .fotorama__dot {
            background: $color__brand;
            border: none;
            width: 8px;
            height: 8px;
            opacity: .64;
            border-radius: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &.fotorama__active .fotorama__dot {
            width: 12px;
            height: 12px;
            opacity: 1;
        }
    }

    // Gallery labels
    .product-gallery-labels {
        position: absolute;
        top: 32px;
        bottom: 0;
        z-index: 10;
        width: 100%;
        pointer-events: none;

        @include media('>=tablet_portrait') {
            width: calc(100% - 104px);
            left: 104px;
        }


        .product-label {
            @include text-menu-item;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            //top: 16px;
            background: $color__brand;
            color: white;
            z-index: 10;
            padding: 4px;
            min-width: 88px;

            &--special-price {
                left: 0;
            }

            //&--good-price {
            //    right: 0;
            //    background-color: $color__brand;
            //}

            &--new {
                right: 0;
                background-color: $color__brand;
            }

            &--custom-label {
                left: 0;
                top: auto;
                bottom: 16px;
                max-width: 80%;
                background-color: $color__brand;
            }
        }
    }

    .product-gallery-certificates {
        display: flex;
        justify-content: space-around;
    }
}
