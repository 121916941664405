$product-info-detailed__background-color: $color__white !default;


.product.info.detailed {
    margin-bottom: 128px;

    .product.data.items {
        @include container-wide;
        position: relative;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-bottom: 0;

        @include media('>=tablet_portrait') {
            @include grid-standard(5);
        }

        &:before {
            content: "";
            background: $product-info-detailed__background-color;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -100vw;
            right: -100vw;
            pointer-events: none;
            z-index: -1;
        }

        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }

    .title {
        margin-bottom: 16px;

        @include media('>=tablet_portrait') {
            grid-row: 1;
        }

        &.active a.switch {
            text-decoration: underline;
            font-weight: bold;

            &:after {
                content: "-";
            }
        }

        a.switch {
            @include button();
            @include button--contrast;

            &:after {
                margin-left: 16px;
                content: "+";

                @include media('>=tablet_portrait') {
                    display: none;
                }
            }

            .counter {
                &:before {
                    content: "(";
                }
                &:after {
                    content: ")";
                }
            }
        }
    }

    .content {
        margin-bottom: 40px;

        @include media('>=tablet_portrait') {
            grid-column: span 5;
            grid-row: 2;
            width: 100%;
        }

        .content {
            padding: 0 !important;
            margin: 0 !important;
        }

        .product.attribute.description {
            .pagebuilder-column-group,
            .pagebuilder-column-line {
                gap: 32px;
                margin-top: 32px;
            }

            img {
            }

            h2 {
                @include heading-h3;
                color: $color__brand;
            }

            h3 {
                @include heading-h4;
                color: $color__brand;
                margin-top: 16px;
                margin-bottom: 4px;
            }

            .value, p, li {
                @include text;
                //color: rgba($color__black, .64);
            }

            [data-content-type="video"] {
                .pagebuilder-video-inner,
                iframe {
                    display: inline-block;
                    aspect-ratio: 16/9;
                    width: 100%;
                }
            }
        }
    }

    .products-grid {
        .product-items {
            @include media('>=tablet_portrait') {
                grid-template-columns: repeat(1, 1fr) !important;
            }

            @include media('>=laptop') {
                grid-template-columns: repeat(2, 1fr) !important;
            }
        }

        .product-item-info {
            @include media('>=tablet_portrait') {
                flex-direction: row;
                align-items: flex-start;
            }
        }

        .product-image-photo {
            @include media('>=tablet_portrait') {
                width: 200px;
                height: 100%;
                min-height: 200px;
                object-fit: cover;
                aspect-ratio: auto;
                border: none !important;
            }
        }

        form[data-role="tocart-form"] {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            label {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 16px;
                font-size: 14px;
            }

            input {
                font-size: 14px;
                padding: 0 0 0 8px;
                min-height: 32px;
                min-width: 0;
                width: 80px;
            }

            .tocart {
                min-width: 80px;
                height: 32px;
                font-size: 14px;

                &.disabled {
                    background-color: $color__brand;
                    cursor: not-allowed;
                }
            }
        }
    }
}
