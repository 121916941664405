.mobile-menu {
  background: $color__white;
  position: fixed;
  top: 56px;
  right: 0;
  left: 0;
  bottom: 0;
  display: none;
  z-index: 101; // below menu

  //Enable scrolling within mobile nav
  overflow-x: hidden;
  overflow-y: auto;

  @include media('>=laptop') {
    display: none !important;
  }

  .field__item {
    display: none;
  }


  @at-root body.menu-open {
    overflow: hidden;

    @include media('>=laptop') {
      overflow: unset;
    }
  }

  > .main-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    border-bottom: 1px solid $color__black;

    > .menu-item {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      border-top: 1px solid $color__black;
      background: $color__white;

      a, span {
        @include text;
        font-weight: bold;
        line-height: 24px;
        display: flex;
        align-items: center;
        padding: 12px 24px;
        color: $color__white;
        text-decoration: none;
        margin: 0;
        flex: 1 0 auto;
        width: calc(100% - 48px);
        user-select: none;
        cursor: pointer;
      }

      &--collapsed,
      &--expanded {
        > a {
          padding-right: 48px;
        }

        &:after {
          content: "add";
          position: absolute;
          right: 0;
          padding: 12px;
          color: $color__white;
        }
      }

      &--expanded:after {
        content: "remove";
      }

      &--active-trail {
        > a {
          background: $color__white;
          color: $color__black;
        }

        &:after {
          color: $color__black;
        }
      }

      .submenu {
        flex: 1 0 100%;
        margin: 0;
        padding: 0;

        ul {
          margin: 0;
          padding: 0;
        }

        .menu-item {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          margin: 0;
          border-top: 1px solid $color__black;
          color: $color__black;

          &:first-of-type {
            border-top: none;
          }

          > a {
            @include text;
            color: $color__white;
            font-weight: bold;
            padding: 12px 24px 12px 40px;
            margin: 0;
          }

          &--active-trail {
            > a {
              background: $color__white;
              color: $color__black;
            }
          }
        }
      }
    }
  }
}

@at-root body {
  &.toolbar-vertical,
  &.toolbar-horizontal {
    .mobile-menu {
      top: 238px;
    }
  }
}

.mobile-extra {
  list-style: none;
  margin: 0;
  padding: 16px 0;

  li {
    margin: 0;
    padding: 0;
  }

  a, span {
    @include text;
    display: flex;
    align-items: center;
    padding: 12px 24px;
    color: $color__white;
    text-decoration: none;
    margin: 0;
    flex: 1 0 auto;
    width: calc(100% - 48px);
    user-select: none;
    cursor: pointer;
  }
}
