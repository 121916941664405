
// On Checkout page, shipping choice
.wexo-shipping-additional .ws-parcelshop {
    padding: 24px;

    .ws-title {
        @include heading-h4;
    }

    .action {
        margin-top: 16px;
    }

    .field-error {
        top: 8px;
    }

    .company {
        font-weight: bold;
    }

    .cancel-parcel-shop {
        margin-top: 16px;
        @include button;
    }
}

// In modal
.modal-popup.ws-parcelshop-popup {
    .leaflet-container,
    .mapboxgl-map {
        height: inherit;
    }

    .ws-map-wrapper {
        height: 30vh;
        margin-bottom: 24px;
    }

    &._show {
        z-index: 910;
    }

    button {
        margin-top: 16px;
        @include button;
    }

    .modal-header {
        display: block;

        .ws-title {
            @include heading-h4;
        }

        .action-close {
            position: absolute;
            top: 8px;
            right: 8px;
            display: flex;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            min-width: 0;
            margin: 0;

            &:after {
                content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="%23ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><path d="M18 6 6 18M6 6l12 12"/></svg>');
                margin: 0;
                line-height: 16px;
            }

            span {
                display: none;
            }
        }
    }

    .modal-content {
        padding: 0;

        .ws-parcel-shop-results {
            .change-location {
                margin-top: 24px;
            }
        }

        .ws-parcel-shop-list {
            margin-top: 24px;
            //max-height: 60vh;
            overflow-y: auto;
            min-height: calc(100% - 120px);

            .ws-parcel-shop-entry {
                @include text;
                padding: 24px 24px;
                cursor: pointer;
                margin: 0;

                &:not(:first-child) {
                    border-top: 1px solid #E6F0EF;
                }

                .company {
                    font-weight: bold;
                }

                &:hover{
                    background: rgba(#E6F0EF, .5);
                }

                &.active {
                    background: #E6F0EF;
                }
            }
        }

        .ws-parcelshop-popup {
            display: flex;
            gap: 24px;

            & > div {
                flex: 1;
            }
        }

        .ws-parcel-shop-content {
            display: flex;
            flex-direction: column;

            .ws-parcel-shop-info {
                flex: 1;
                display: flex;
                flex-direction: column;

                .choose-parcel-shop {
                    margin-top: 15px;
                    display: flex;
                    justify-content: center;
                }

                .parcel-shop-info-opening-hours {
                    margin-top: 15px;
                    font-size: 0.9em;

                    table > tbody > tr > th, table > tbody > tr > td {
                        padding: 3px 9px;
                    }

                    .day {
                        font-weight: 500;
                    }
                }
            }

            .parcel-shop-info-parcelshop {
                .company {
                    font-size: 1.2em;
                    font-weight: bold;
                }
            }
        }
    }
}

//.media-width(@extremum, @break) when (@extremum = 'max') and (@break = @screen__s) {
@include media('<tablet') {
    body .modal-popup.ws-parcelshop-popup {
        inset: 16px;

        .modal-inner-wrap {
            margin: 0;
            width: 100%;
            height: 100%;
            padding: 32px 8px;
        }

        .modal-header .ws-title {
            text-align: center;
            margin-top: 16px;
        }
    }

    body .modal-popup.ws-parcelshop-popup .modal-content .ws-parcelshop-popup {
        display: block;
    }
    body .wexo-shipping-additional .ws-shipping-method-content .ws-search-button {
        width: auto;
    }
}
