.limiter {
    display: inline-flex;
    align-items: center;
    gap: 8px;

    .limiter-options {
        margin: 0;

        line-height: 48px;
    }

    .limiter-text {
        white-space: nowrap;
    }
}
