img {
  display: block;
  max-width: 100%;
  height: auto;
}

.image--cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
