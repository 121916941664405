
$footer__background: $color__brand !default;

.page-footer {
    //background: $footer__background;

    .footer.content {
        @include container-wide;
        display: flex;
        flex-direction: column;
        border-top: unset;
        padding-top: 16px;
        padding-bottom: 40px;
        margin-bottom: 0;
        position: relative;

        @include media('>=tablet') {
            padding-top: 72px;
        }

        &:before {
            content: "";
            position: absolute;
            left: -50vw;
            right: -50vw;
            top: 0;
            bottom: 0;
            background: $footer__background;
        }

        > * {
            // make all children stay on top of the background
            position: relative;
        }
    }

    .links {
        @include grid-standard(1);

        @include media('>=tablet') {
            grid-template-columns: 4fr 8fr;
        }
    }

    .block-static-block {
        header {
            @include heading-h2;
            color: $color__white;
            text-transform: uppercase;

            @include media('>tablet_portrait') {
                font-size: 32px;
            }
        }

        strong {
            display: block;
            padding-top: 16px;
        }

        p, a, span {
            color: $color__white;
            text-decoration: none;
            margin-bottom: 8px;
        }
    }

    .ninjamenus {
        background: transparent !important;
        overflow-x: unset;

        &.ninjamenus-mobile {
            margin-top: 32px;

            .opener {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 56px;
                height: 56px;

                &:before {
                    content: "";
                    width: 24px;
                    height: 24px;
                    display: block;
                    mask-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    background: $color__white;
                    transition: transform 300ms;
                }
            }

            // force the menu to be open on mobile,
            // when using the class .start-open
            .start-open .item-submenu {
                display: block;
            }
        }

        &--ninjamenus-desktop {
            margin-top: 0;
        }

        &--ninjamenus-drilldown {
            border: none;
        }

        .ninjamenus-toggle-active > .opener:before {
            transform: translate(-50%,-50%) rotate(180deg);
        }

        a {
            @include text;
            background: none !important;
            display: flex !important;
            color: $color__white;
            text-decoration: none;
        }

        .level0 > a {
            font-weight: bold;
            padding: 16px 0;
            margin: 0;
        }

        .item-submenu a {
            padding: 8px 0;
        }
    }

    .magezon-builder {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @include media('>=tablet') {
            flex-direction: row;
        }
    }

    .footer-bottom {
        @include grid-standard(1);
        margin-top: 48px;

        @include media('>=tablet') {
            @include grid-standard(2);
        }
    }

    .footer-quicklinks {
        @include grid-standard(1);
        margin-top: 48px;

        @include media('>=tablet') {
            grid-template-columns: 3fr 9fr;
        }

        [data-content-type="image"] {
            max-width: 119px;
        }
    }
}
