.product-item-details {

    .product-item-name {
        &, a {
            @include heading-h5;
            text-decoration: none;
        }
    }

    .item-options {
        //@include grid-standard(2);
        //grid-gap: 8px;
        //
        //font-size:12px;

        dt {
            font-weight: bold;
        }

        dd {
            @include text;

            margin-bottom: 0;
            margin-left: 0;
            //font-size: 12px;
        }
    }
}
