
.table-checkout-shipping-method {
    th {
        &.col {
            display: none;
        }
    }

    tr {
        display: grid;
        grid-template-columns: 24px 1fr 2fr;
    }

    td {
        padding: 4px !important;
        border: none !important;

        &.col-method {
            font-weight: 700
        }
        &.col-carrier {
            grid-row: 2;
            grid-column: 2 / span 2;
        }
    }

    tbody tr:not(:last-child) {
        border-bottom: 1px solid #eee;
        padding-bottom: 16px;
        margin-bottom: 16px;
    }
}