.product-dimensions {
    &__title {
        @include text-menu-item;
        margin-bottom: 8px !important;
    }

    &__value {
        @include heading-h4;
        margin-bottom: 24px !important;
        color: $color__brand;
    }

    &__image {
        margin-bottom: 16px;
    }
}
