.customer-account-login .page-main {
    .page-title-wrapper {
        @include container-wide;
        margin: 32px auto;
    }

    .columns {
        @include container-wide;
    }

    .login-container {
        @include grid-standard(1);

        @include media('>=tablet_portrait') {
            @include grid-standard(2);
        }
    }

    .actions-toolbar {
        justify-content: space-between;
    }

    .field.choice {
        display: block;
        padding-left: 32px;
        position: relative;

        input {
            position: absolute;
            top: 0;
            left: 0;
        }

        .tooltip.wrapper {
            display: block;
            border: 1px solid #478f51;
            padding: 16px;
        }
    }
}
